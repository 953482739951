import { Dialog, FocusStyleManager } from "@blueprintjs/core";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isBoolean, isString } from "utils";
import styles from "./App.module.css";
import { Provider } from "./app/appContext.js";
import {
  selectApprove,
  selectBullhornActive,
  setBullhornActive,
  setBullhornReady,
  selectWarning,
  setApprove,
  //loadNotifications,
  setupNotificationChannel,
  setWarning,
  selectBullhornReady,
} from "./app/appSlice.js";
import { appState } from "./app/appState.js";
import {
  FILE_CREATE,
  TEAM_CREATE,
  TEAM_TEAMMATE_ADD,
  WORKSPACE_TEAMMATE_INVITE,
  WORKSPACE_GET_BULLHORN_ZONE,
  WORKSPACE_SET_BULLHORN_ZONE,
  WORKSPACE_GET_CONFIG,
} from "./app/permissions";
import { RolesProvider } from "./app/rolesContext.js";
import {
  selectIdentity,
  selectOffline,
  selectReady,
  selectRedirecting,
} from "./app/topSlice.js";
import { Account } from "./features/account/Account";
import { Activity } from "./features/activity/Activity";
import { Admin } from "./features/admin/Admin";
import Archived from "./features/archived/Archived";
import BhController from "./features/bullhorn/BhController";
import { setHasPermissions } from "./features/bullhorn/bullhornSlice";
import { Chat } from "./features/chat/Chat";
import Contexts from "./features/contexts/Popover.js";
import { Debug } from "./features/debug/Debug";
import Dialogs from "./features/dialogs/Dialogs.js";
import { Ditto } from "./features/ditto/Ditto.js";
import { Editor } from "./features/editor/Editor";
import { selectShowEditor } from "./features/editor/editorSlice.js";
import { File } from "./features/file/File";
import { Footer } from "./features/footer/Footer";
import { Header } from "./features/header/Header";
import { Layout } from "./features/layout/Layout";
import {
  setWindowSizeNAdjustCeil /*, selectWindowWidth, selectWindowHeight*/,
} from "./features/layout/layoutSlice.js";
import { Login } from "./features/login/Login";
import { Menu } from "./features/menu/Menu";
import Overlays from "./features/overlays/Overlays.js";
import { Search } from "./features/search/Search";
import Toasts from "./features/toasts/Toasts.js";
import { Transfer } from "./features/transfer/Transfer";
import { Tree } from "./features/tree/Tree";
import { User } from "./features/users/User";
import { Users } from "./features/users/Users";
import { Zones } from "./features/zones/zones.js";
import BrowserNotSupported from "./helpers/browser-not-supported";
import { hasSupport } from "./helpers/detect-browser";
import onEnterEffect from "./helpers/on-enter-effect";

//import Guilloche from "./guilloche";

const {
  derive: { getContext },
  selectors: { selectInitialized, selectGraph, selectPermissions },
  actions: { remote, initialize, loadZone },
  //server: { fetchZoneFlat }
} = appState;

FocusStyleManager.onlyShowFocusOnTabs();

export const debounce = (func, delay) => {
  let inDebounce;
  return function () {
    const context = this;
    const args = arguments;
    clearTimeout(inDebounce);
    inDebounce = setTimeout(() => func.apply(context, args), delay);
  };
};

let first = true;

const App = React.memo(() => {
  const dispatch = useDispatch();

  //const windowWidth = useSelector(selectWindowWidth);
  //const windowHeight = useSelector(selectWindowHeight);
  const initialized = useSelector(selectInitialized);
  const ready = useSelector(selectReady);
  const redirecting = useSelector(selectRedirecting);
  const offline = useSelector(selectOffline);
  const identity = useSelector(selectIdentity);
  const showEditor = useSelector(selectShowEditor);
  const approveState = useSelector(selectApprove);
  const warningState = useSelector(selectWarning);
  const bullhornActive = useSelector(selectBullhornActive);
  const bullhornReady = useSelector(selectBullhornReady);
  const permissions = useSelector(selectPermissions);
  const showNotSupported = !hasSupport(
    ["linux", "windows", "macos", "chromeos", "android", "ios"],
    {
      chrome: ">=87",
      chromium: ">=87",
      edge: ">=87",
      firefox: ">=86",
      safari: ">=14",
      android: ">=88",
    }
  );

  const graph = useSelector(selectGraph);
  const context = useMemo(() => getContext(graph, identity), [graph, identity]);
  const { zone, notLoaded } = context;

  if (!initialized) {
    dispatch(initialize());
  }

  if (identity && zone && notLoaded) {
    // TODO that happens twice for first zone
    dispatch(loadZone({ id: zone.id }));
    //// TODO this may need to be synced better...
    //dispatch(getChannel()).then(channel =>
    //  channel.subscribe([-1, identity.id, zone.id])
    //);
    //dispatch(remote(fetchZoneFlat({ id: zone.id })));
  }

  if (first) {
    dispatch(setupNotificationChannel());
    //dispatch(loadNotifications());
    first = false;
  }

  useEffect(() => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
    window.addEventListener(
      "resize",
      debounce(() => {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty("--vh", `${vh}px`);
        const payload = {
          width: window.innerWidth,
          height: window.innerHeight,
        };
        dispatch(setWindowSizeNAdjustCeil(payload));
      }, 100)
    );
  }, []);

  useEffect(() => {
    if (bullhornReady && Object.keys(permissions).length) {
      if (
        !(
          permissions[TEAM_CREATE] &&
          permissions[FILE_CREATE] &&
          permissions[TEAM_TEAMMATE_ADD] &&
          permissions[WORKSPACE_TEAMMATE_INVITE] &&
          permissions[WORKSPACE_GET_BULLHORN_ZONE] &&
          permissions[WORKSPACE_SET_BULLHORN_ZONE] &&
          permissions[WORKSPACE_GET_CONFIG]
        )
      ) {
        dispatch(setBullhornReady(false));
        dispatch(setBullhornActive(false));
        alert(
          "You do not have sufficient permissions to launch the bullhorn integration!"
        );
      } else {
        dispatch(setHasPermissions(true));
        dispatch(setBullhornActive(true));
      }
    }
  }, [bullhornReady, Object.keys(permissions).length]);

  return (
    <Provider value={context}>
      <RolesProvider>
        {/*<Guilloche ww={windowWidth} wh={windowHeight} />*/}
        <div className={styles.outer}>
          <div className={styles.inner}>
            {(showNotSupported && <BrowserNotSupported />) ||
              (redirecting && <Redirecting />) ||
              (offline && <Offline />) ||
              (!ready && <Loading />) ||
              (!identity && <Login />) ||
              (showEditor && <Editor />) || (
                <Layout
                  header={Header}
                  footer={Footer}
                  menu={Menu}
                  tabs={{
                    debug: Debug,
                    zones: Zones,
                    admin: Admin,
                    ditto: Ditto,
                    docs: Tree,
                    search: Search,
                    archived: Archived,
                    file: File,
                    chat: Chat,
                    user: User,
                    users: Users,
                    activity: Activity,
                    account: Account,
                    transfer: Transfer,
                  }}
                />
              )}
          </div>
          <div className={styles.lower}>
            <span>Protected with end-to-end encryption (E2EE) by HYKER</span>
            <span>Version 3.2.34</span>
          </div>
        </div>
        {warningState && <WarningDialog />}
        {isBoolean(approveState) || <ApproveDialog />}
        <Contexts />
        {bullhornActive && <BhController />}
        <Dialogs />
        <Overlays />
        <Toasts />
      </RolesProvider>
    </Provider>
  );
});

export default App;

export const Offline = () => (
  <div className={styles.offline}>
    <div>You seem to be offline</div>
    <button
      className={styles.dialogButton}
      onClick={() => window.location.reload()}
    >
      Reload
    </button>
  </div>
);

export const Redirecting = () => (
  <div className={styles.redirecting}>
    <div />
  </div>
);

export const Loading = () => (
  <div className={styles.loading2}>
    <div />
    <div />
    <div />
  </div>
);

export const WarningDialog = (props) => {
  const dispatch = useDispatch();
  const warningState = useSelector(selectWarning);
  useEffect(
    onEnterEffect((off) => (off(), window.location.reload())),
    []
  );

  return (
    <Dialog
      isOpen={true}
      onClose={() => dispatch(setWarning())}
      className={styles.warningDialog}
    >
      <div className={styles.warningBody}>
        <p className={styles.warningHeader}>We Are Sorry</p>
        <p className={styles.warningReason}>{warningState}</p>
        <div className={styles.warningFooter}>
          <p className={styles.warningReport}>An error report has been sent.</p>
          <button
            className={styles.warningButton}
            onClick={() => dispatch(setWarning())}
          >
            Ignore
          </button>
          <button
            className={styles.warningButton}
            onClick={() => window.location.reload()}
          >
            Reload
          </button>
        </div>
      </div>
    </Dialog>
  );
};

export const ApproveDialog = (props) => {
  const dispatch = useDispatch();
  const approveState = useSelector(selectApprove);

  let primary = approveState,
    secondary = "",
    notice = false;

  if (!isString(primary)) {
    [primary, secondary = "", notice = false] = approveState;
  }

  return (
    <Dialog
      isOpen={true}
      onClose={() => dispatch(setApprove(false))}
      className={styles.approveDialog}
    >
      <div className={styles.approveBody}>
        <p className={styles.approvePrimary}>{primary}</p>
        <p className={styles.approveSecondary}>{secondary}</p>
        <div className={styles.approveButtons}>
          {!notice && (
            <button
              className={styles.approveCancel}
              onClick={() => dispatch(setApprove(false))}
            >
              Cancel
            </button>
          )}
          <button
            autoFocus
            className={styles.approveOkey}
            onClick={() => dispatch(setApprove(true))}
          >
            Okay
          </button>
        </div>
      </div>
    </Dialog>
  );
};
