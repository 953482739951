import { useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  containsVideoFile,
  PrepareFile,
  prepareVideoDialog,
  setPrepareFile,
} from "../../features/transfer/transferSlice.js";
import { Intent } from "@blueprintjs/core";
import { dispatchToast } from "../../features/toasts/Toasts.js";
import { VIDEO_INFO } from "features/dialogs/Dialogs.js";
import { setDialog } from "app/appSlice.js";
import styles from "./main.module.css";

const MAX_SIZE_MB = 800;
const MIN_SIZE = 300 * 1024 * 1024;
const MAX_SIZE = 800 * 1024 * 1024;
const TIME_OUT_SHORT = 1500;
const TIME_OUT_MEDIUM = 5000;
const TIME_OUT_LONG = 10000;
let TIME_OUT = 0;

export default function useFileInput({ video = false } = {}) {
  const dispatch = useDispatch();
  const callback = useRef(null);
  const input = useRef(null);

  const handleFileSizeErrors = (files, dispatch, el, e) => {
    const filesBetween300AndMaxSize = Array.from(files).filter(
      (file) => file.size >= MIN_SIZE && file.size <= MAX_SIZE
    );
    const filesLargerThanMaxSize = Array.from(files).filter(
      (file) => file.size > MAX_SIZE
    );

    if (filesLargerThanMaxSize.length > 0) {
      dispatch(
        setDialog({
          dialog: VIDEO_INFO,
          maxFileSize: MAX_SIZE_MB,
          wrongCodec: false,
          hvec: false,
        })
      );
      e.target.value = "";
      el.parentNode.removeChild(el);
      return true;
    } else if (filesBetween300AndMaxSize.length > 0) {
      TIME_OUT = TIME_OUT_LONG;
    } else {
      TIME_OUT = TIME_OUT_MEDIUM;
    }
    return false;
  };

  const handleVideoFormat = (files, dispatch, el, e) => {
    const videoFileExtensions = ["mp4", "mov", "MP4", "MOV"];
    const filesArray = Array.isArray(files) ? files : Array.from(files);

    for (const file of filesArray) {
      const fileExtension = file.name.split(".").pop().toLowerCase();
      const isVideoFile = file.type.startsWith("video/");
      const isNotMp4OrMov = !videoFileExtensions.includes(fileExtension);

      if (isVideoFile && isNotMp4OrMov) {
        dispatch(
          setDialog({
            dialog: VIDEO_INFO,
            maxFileSize: MAX_SIZE_MB,
            wrongCodec: true,
            hvec: false,
          })
        );
        e.target.value = "";
        el.parentNode.removeChild(el);
        return true;
      }
    }

    return false;
  };

  const handleVideoHEVC = (dispatch, el, e) => {
    dispatch(
      setDialog({
        dialog: VIDEO_INFO,
        maxFileSize: MAX_SIZE_MB,
        wrongCodec: false,
        hvec: true,
      })
    );
    e.target.value = "";
    el.parentNode.removeChild(el);
  };

  useEffect(() => {
    const el = document.createElement("input");
    el.type = "file";
    el.multiple = true;
    el.className = styles.hiddenFileInput;

    if (video) {
      el.accept = "video/mp4,video/quicktime, .mov,.MOV,.mp4,.MP4";
    }

    el.addEventListener("change", async (e) => {
      const { files } = e.target;

      if (video) {
        await dispatchToast(dispatch, {
          message: "Analyzing video...",
          icon: "SPINNER",
          intent: Intent.PRIMARY,
          timeout: TIME_OUT_SHORT,
        });
      }

      if (video) {
        if (handleFileSizeErrors(files, dispatch, el, e)) {
          return;
        }
      }

      if (video) {
        if (handleVideoFormat(files, dispatch, el, e)) {
          return;
        }
      }

      const isVideo = await containsVideoFile(files);

      if (isVideo.video && video) {
        if (isVideo.fragmented) {
          if (callback.current) {
            callback.current(files);
          }
          return;
        }

        if (!isVideo.h264) {
          handleVideoHEVC(dispatch, el, e);
          return;
        }

        const prepareFileResult = await dispatch(
          prepareVideoDialog(MAX_SIZE_MB)
        );

        if (prepareFileResult === PrepareFile.DENIED) {
          e.target.value = "";
          el.parentNode.removeChild(el);
          dispatch(setPrepareFile(PrepareFile.NOT_STARTED));
          return;
        }

        if (prepareFileResult === PrepareFile.CONFIRMED) {
          await dispatchToast(dispatch, {
            message: "Preparing video for preview...",
            icon: "SPINNER",
            intent: Intent.PRIMARY,
            timeout: TIME_OUT,
          });
        }

        if (callback.current) {
          callback.current(files);
        }
      } else {
        if (callback.current) {
          callback.current(files);
        }
      }

      if (e && e.target) {
        e.target.value = "";
      }
      el.parentNode.removeChild(el);
    });

    document.body.appendChild(el);
    input.current = el;

    return () => {
      if (!callback.current) {
        el.parentNode.removeChild(el);
      }
    };
  }, []);

  return (cb) => {
    if (input.current) {
      input.current.click();
      callback.current = cb;
    }
  };
}
