export const FILE_SYS_API = "showDirectoryPicker" in window;

export async function selectFolder() {
  let folder;
  try {
    folder = await window.showDirectoryPicker({});
  } catch (e) {
    folder = e instanceof Error ? e : new Error(`${e}`);
  }
  if (folder instanceof Error) {
    return folder;
  } else {
    return folder;
  }
}

export function createFileEntryItFactory(item) {
  if (item instanceof DataTransferItemList) {
    if (!item[0]?.getAsFileSystemHandle) {
      const entries = dataTransferItemListToWebkitEntry(item);
      return async function* () {
        for (const entry of entries) {
          if (entry instanceof Error) {
            yield entry;
          } else {
            yield* iterateFileSystemEntry(entry);
          }
        }
      };
    } else {
      const promise = dataTransferItemListToFileSystemHandles(item);
      return async function* () {
        for (const entry of await promise) {
          if (entry instanceof Error) {
            yield entry;
          } else {
            yield* iterateFileSystemDirectoryHandle(entry);
          }
        }
      };
    }
  }
  return async function* () {
    if (item instanceof FileSystemDirectoryHandle) {
      yield* iterateFileSystemDirectoryHandle(item, item);
    } else {
      yield* iterateFileSystemFileHandles(item);
    }
  };
}

async function* iterateFileSystemDirectoryHandle(entry, root) {
  const name = normalizeText(entry.name);
  let path = `${name}`;
  if (root) {
    let paths;
    try {
      paths = await root.resolve(entry);
      if (!paths) {
        paths = new Error("Could not get path from file system file handle.");
      }
    } catch (e) {
      paths = e instanceof Error ? e : new Error(`${e}`);
    }
    if (paths instanceof Error) {
      yield paths;
      return;
    }
    paths.unshift(normalizeText(root.name));
    path = paths.join("/");
  }
  if (entry.kind === "file") {
    let file;
    try {
      file = await entry.getFile();
      if (!file) {
        file = new Error("Could not get file from file system file handle.");
      }
    } catch (e) {
      file = e instanceof Error ? e : new Error(`${e}`);
    }
    if (file instanceof Error) {
      yield file;
    } else {
      yield {
        name,
        path,
        isFolder: false,
        file,
      };
    }
  } else if (entry.kind === "directory") {
    let it;
    try {
      it = entry.values();
    } catch (e) {
      it = e instanceof Error ? e : new Error(`${e}`);
    }
    if (it instanceof Error) {
      yield it;
    } else {
      yield {
        name,
        path,
        isFolder: true,
      };
      for await (const item of it) {
        yield* iterateFileSystemDirectoryHandle(item, root || entry);
      }
    }
  }
}
function dataTransferItemListToWebkitEntry(items) {
  const entries = [];
  for (const item of items) {
    let entry;
    if (item.kind !== "file") {
      entry = new Error("Data transfer item was not of kind file.");
    } else {
      try {
        entry = item.webkitGetAsEntry();
        if (!entry) {
          entry = new Error("Could not get entry from data transfer item.");
        }
      } catch (e) {
        entry = e instanceof Error ? e : new Error(`${e}`);
      }
    }
    entries.push(entry);
  }
  return entries;
}

async function* iterateFileSystemEntry(entry, paths = []) {
  const name = normalizeText(entry.name);
  paths = [...paths, name];
  const path = paths.join("/");
  if (entry.isFile === true) {
    let file;
    try {
      file = await new Promise((resolve, reject) => {
        try {
          entry.file(resolve, reject);
        } catch (e) {
          reject(e);
        }
      });
      if (!file) {
        file = new Error("Could not get file from file system file entry.");
      }
    } catch (e) {
      file = e instanceof Error ? e : new Error(`${e}`);
    }
    if (file instanceof Error) {
      yield file;
    } else {
      yield {
        name,
        path,
        isFolder: false,
        file,
      };
    }
  } else if (entry.isDirectory === true) {
    let reader;
    try {
      reader = entry.createReader();
      if (!reader) {
        reader = new Error(
          "Could not create reader from file system directory entry."
        );
      }
    } catch (e) {
      reader = e instanceof Error ? e : new Error(`${e}`);
    }
    if (reader instanceof Error) {
      yield reader;
      return;
    }
    let entries;
    try {
      entries = await new Promise((resolve, reject) => {
        try {
          reader.readEntries(resolve, reject);
        } catch (e) {
          reject(e);
        }
      });
      if (!entries) {
        entries = new Error(
          "Could not read entries from file system directory reader."
        );
      }
    } catch (e) {
      entries = e instanceof Error ? e : new Error(`${e}`);
    }
    if (entries instanceof Error) {
      yield entries;
      return;
    }
    yield {
      name,
      path,
      isFolder: true,
    };
    for (const entry of entries) {
      yield* iterateFileSystemEntry(entry, paths);
    }
  }
}

async function* iterateFileSystemFileHandles(entries) {
  for (const entry of entries) {
    yield* iterateFileSystemDirectoryHandle(entry);
  }
}
function dataTransferItemListToFileSystemHandles(items) {
  const entries = [];
  for (const item of items) {
    let entry;
    if (item.kind !== "file") {
      entry = new Error("Data transfer item was not of kind file.");
    } else {
      entry = item.getAsFileSystemHandle();
    }
    entries.push(entry);
  }
  return Promise.all(entries);
}

export function normalizeText(
  text,
  stripSurrogatesAndFormats = false,
  stripLineSeparator = false
) {
  text = text.normalize("NFC");

  // // strip control chars. optionally, keep surrogates and formats
  // if(stripSurrogatesAndFormats) {
  //   text = text.replace(/\p{C}/gu, '');
  // } else {
  //   text = text.replace(/\p{Cc}/gu, '');
  //   text = text.replace(/\p{Co}/gu, '');
  //   text = text.replace(/\p{Cn}/gu, '');
  // }
  // normalize newline
  //text = text.replace(/\n\r/g, "\n");
  //text = text.replace(/\p{Zl}/gu, "\n");
  //text = text.replace(/\p{Zp}/gu, "\n");
  if (stripLineSeparator) {
    text = text.replace(/\n\r|\p{Zl}|\p{Zp}/gu, "");
  } else {
    text = text.replace(/\n\r|\p{Zl}|\p{Zp}/gu, "\n");
  }

  // normalize space
  text = text.replace(/\p{Zs}/gu, " ");

  if (stripSurrogatesAndFormats) {
    text = text.replace(/[^\p{L}\p{M}\p{N}\p{P}\p{S}\p{Z}]/gu, "");
  } else {
    text = text.replace(/[^\p{L}\p{M}\p{N}\p{P}\p{S}\p{Z}\p{Cf}\p{Cs}]/gu, "");
  }

  return text;
}
