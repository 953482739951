import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Intent, Tag } from "@blueprintjs/core";
import { Dialog, Icon } from "@blueprintjs/core";
import { keys, classNames } from "utils";
import useFileInput from "../../helpers/use-file-input";
import appContext from "../../app/appContext.js";
import { selectVideoPreview, setDialog } from "../../app/appSlice.js";
import { startUpload } from "../transfer/transferSlice.js";
import { FOLDER_CREATE } from "../dialogs/Dialogs.js";
import styles from "./Dialogs.module.css";
import { appState } from "../../app/appState.js";
const {
  actions: { setCurrentNode },
} = appState;

export default ({ dispatch, active, close, item }) => {
  const triggerFileInput = useFileInput();
  const triggerVideoFileInput = useFileInput({ video: true });
  const isVideoPreview = useSelector(selectVideoPreview);

  const context = useContext(appContext);
  const { zoneId } = context;
  let folderId, groupId;
  if (!item || !keys(item).length) {
    ({ folderId, groupId } = context);
  } else if ("storageId" in item) {
    folderId = item.parent;
    groupId = item.group;
  } else {
    folderId = item.id;
    groupId = item.group;
  }
  return (
    <Dialog isOpen={active} onClose={close} className={styles.dialog}>
      <div className={styles.dialogBody}>
        <button
          className={classNames(styles.dialogButton, styles.full)}
          onClick={() => {
            close();
            dispatch(setCurrentNode(folderId));
            dispatch(setDialog({ dialog: FOLDER_CREATE }));
          }}
        >
          <Icon icon="folder-new" />
          <span>Create Folder</span>
          <span />
        </button>
        <div className={styles.verticalSpace} />
        <button
          className={classNames(styles.dialogButton, styles.full)}
          onClick={() => {
            close();
            triggerFileInput((files) => {
              if (files) {
                dispatch(startUpload(files, groupId, folderId, zoneId));
              }
            });
          }}
        >
          <Icon icon="upload" />
          <span>Upload Files</span>
          <span />
        </button>
        <div className={styles.verticalSpace} />
        {isVideoPreview && (
          <button
            className={classNames(styles.dialogButton, styles.full)}
            onClick={() => {
              close();
              triggerVideoFileInput((files) => {
                if (files) {
                  dispatch(startUpload(files, groupId, folderId, zoneId));
                }
              });
            }}
          >
            <Tag>Beta</Tag>
            <span className={styles.buttonText}>Upload Video</span>
            <span />
          </button>
        )}
        <div className={styles.verticalSpace} />
        <button
          className={classNames(
            styles.dialogButton,
            styles.full,
            styles.neutral
          )}
          onClick={close}
        >
          <Icon icon="chevron-left" />
          <span>Cancel</span>
          <span />
        </button>
      </div>
    </Dialog>
  );
};
