import React from "react";
import { Callout, Intent } from "@blueprintjs/core";
import { classNames } from "utils";
import styles from "./Preview.module.css";

const COMPACT = false;
const INTENT = Intent.WARNING;
const ICON = "warning-sign";
const PREVIEW_ERROR = (
  <span>
    If your MOV file was uploaded before the video feature was released, please
    re-upload it so the file can be converted to MP4 for preview.
  </span>
);

const DECRYPT_MESSAGE = "Please wait until the complete file has been decrypted.";

const getPlayerErrorMessages = (isMOV) => ({
  1: {
    title: "The video playback was aborted.",
    message: `The video loading was aborted. ${DECRYPT_MESSAGE}`,
  },
  2: {
    title: "The video playback was stalled.",
    message: `The video playback has been interrupted. ${DECRYPT_MESSAGE}`,
  },
  3: {
    title: "The video could not be decoded.",
    message: isMOV
      ? PREVIEW_ERROR
      : `The video player can't read the video. ${DECRYPT_MESSAGE}`,
  },
  4: {
    title: "The video format is not supported.",
    message:
      "The video file is not properly fragmented, or there is a problem with the video's codec. Please try uploading it again.",
  },
  5: {
    title: "Preview not available for this file type.",
    message: isMOV
      ? PREVIEW_ERROR
      : "This file type is not supported for preview. Please upload a supported file format if you want to preview a file.",
  },
  6: {
    title: "Error finalizing video stream.",
    message: `An error occurred while finalizing the video stream. ${DECRYPT_MESSAGE}`,
  },
  7: {
    title: "Video preview unavailable",
    message: "Video preview is not supported on this device. You can still upload videos for processing.",
  },
});

const VideoError = ({ error, isMOV, isIphone }) => {
  const playerErrorMessages = getPlayerErrorMessages(isMOV);

  const errorMessageObject = isIphone && isMOV
    ? playerErrorMessages[7]
    : (error && error.code)
    ? playerErrorMessages[error.code]
    : null;

  const message = errorMessageObject
    ? typeof errorMessageObject.message === "function"
      ? errorMessageObject.message(isMOV)
      : errorMessageObject.message
    : "An unexpected error occurred.";

  return (
    <Callout
      className={classNames(styles.callout)}
      compact={COMPACT ? COMPACT : undefined}
      intent={INTENT}
      icon={ICON}
      title={errorMessageObject?.title || "Video error."}
    >
      <span>{message}</span>
    </Callout>
  );
};

export default VideoError;
