import React from "react";
import { Dialog, Icon, IconSize, Intent } from "@blueprintjs/core";
import styles from "./Dialogs.module.css";

const FILE_UPLOAD_MESSAGE = "To store the file, use the file upload option.";

const FILE_UPLOAD_INFO = (
  <aside>
    <Icon icon="info-sign" size={IconSize.SMALL} intent={Intent.PRIMARY} />
    <p>{FILE_UPLOAD_MESSAGE}</p>
  </aside>
);

const getDialogContent = (wrongCodec, hvec, maxFileSize) => {
  if (wrongCodec) {
    return {
      title: "Incompatible file format",
      info: (
        <section>
          <p>
            Currently, only <span className={styles.size}>MP4</span> video files
            can be previewed.
            <span className={styles.size}> MOV</span> files will be prepared and
            saved as <span className={styles.size}>MP4</span> before being
            previewed.
          </p>
          {FILE_UPLOAD_INFO}
        </section>
      ),
    };
  }

  if (hvec) {
    return {
      title: "The video file is encoded with HEVC",
      info: (
        <section>
          <h4>
            The video file is encoded with the HEVC (H.265) codec, which is
            currently not supported. Konfident is only compatible with H.264
            encoding.
          </h4>
          <p>
            On your iPhone, go to <span>Settings</span> <span>Camera</span>. Tap{" "}
            <span>Formats</span>, then select <span>Most Compatible</span>. This
            will set the format to H.264 for future recordings.
          </p>
          <br />
          <p>
            On your Android device, the option to select H.264 encoding may vary
            depending on the manufacturer and model. If available, go to the {" "}
            <span>Camera</span> app's <span>Settings</span> (usually a gear
            icon), and look for <span>High efficiency video</span> or {" "}
            <span>Advanced Video Settings</span>. If the{" "}
            <span>High efficiency</span> option is turned on, switch it off to
            ensure recordings are made in H.264. If no such option exists, your
            device may already default to H.264 for recordings.
          </p>

          <br />
          <p>
            Please note: Changing these settings will only apply to new videos
            you record. Existing videos encoded in HEVC (H.265) will not be
            compatible with Konfident.
          </p>

          {FILE_UPLOAD_INFO}
        </section>
      ),
    };
  }

  return {
    title: "The video file is too large",
    info: (
      <section>
        <p>
          The maximum file size for video previews is{" "}
          <span className={styles.size}>{maxFileSize} MB.</span>
        </p>
        {FILE_UPLOAD_INFO}
      </section>
    ),
  };
};

export default ({ active, close, wrongCodec, hvec, maxFileSize }) => {
  const { title, info } = getDialogContent(wrongCodec, hvec, maxFileSize);
  return (
    <Dialog
      isOpen={active}
      onClose={close}
      icon="warning-sign"
      className={styles.dialog}
      title={title}
    >
      <div className={styles.previewBody}>{info}</div>
    </Dialog>
  );
};
