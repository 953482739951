import { createSlice } from "@reduxjs/toolkit";
import {
  isBoolean,
  createMetaHooks,
  createSagaActions,
  nanoid,
  middlewareConcurrency,
} from "utils";
import { selectIdentity, getToken } from "./topSlice.js";
import {
  requestUploadCredentials,
  requestUpdateCredentials,
  requestDownloadCredentials,
  fetchStickyNotifications,
  fetchNotifications,
} from "./realworld";
import { API_URL_PREFIX_NOTIFY_WS } from "../config";
import { appState } from "./appState.js";
import { hasWebPushSupport, setupWebPush } from "./web-push";
import { releaseNonSerializableValues } from "../helpers/nonSerializableValues/index.js";
import * as Sentry from "@sentry/browser";

///const {
///  actions: { setCurrentNode, resetZones, setExpandedNode, remote, remoteSync },
///  server: { fetchNode, fetchZones },
///  selectors: { selectGraph },
///  derive: { getZoneFromNode }
///} = appState;

const {
  actions: { setCanLoadMore, searchZones },
} = appState;

const { debounce } = middlewareConcurrency;

export const {
  metaActions: { waitFor, observe },
} = createMetaHooks();

const PAGE_SIZE = 20;

const slice = {
  name: "app",
  initialState: {
    notifications: {},
    stickyNotifications: {},
    notificationPageParams: {
      version: 0,
      //sticky: true,
      //start: 0,
      //end: PAGE_SIZE
    },
    dialog: {},
    overlay: {},
    toasts: [],
    warning: null,
    approve: false,
    legalNames: {},
    webPushStatus: undefined,
    bullhorn: {
      ready: false,
      active: false,
      hideMenu: false,
      hideAddZoneButton: false,
    },
  },
  reducers: {
    setNotifications: (state, { payload: notifications }) => {
      state.notifications = Object.fromEntries(
        notifications.map((notification) => [notification.id, notification])
      );
    },
    addNotifications: (state, { payload: notifications }) => {
      notifications.forEach(
        (notification) => (state.notifications[notification.id] = notification)
      );
    },
    setNotificationPageParams: (state, { payload: params }) => {
      state.notificationPageParams = params;
    },
    setStickyNotifications: (state, { payload: stickyNotifications }) => {
      state.stickyNotifications = stickyNotifications;
    },
    setDialog: (scope, { payload: state = {} }) => {
      releaseNonSerializableValues(scope.dialog, state);
      scope.dialog = state;
    },
    setOverlay: (scope, { payload: state = {} }) => {
      scope.overlay = state;
    },
    setToasts: (scope, { payload: state = [] }) => {
      scope.toasts = state;
    },
    setWarning: (scope, { payload: state }) => {
      scope.warning = state;
    },
    setApprove: (scope, { payload: state }) => {
      scope.approve = state;
    },
    setWebPushStatus: (state, { payload: status }) => {
      state.webPushStatus = status;
    },
    setBullhornActive: (state, { payload: active }) => {
      state.bullhorn.active = active;
    },
    setBullhornReady: (state, { payload: ready }) => {
      state.bullhorn.ready = ready;
    },
    setBullhornHideMenu: (state, { payload: hideMenu }) => {
      state.bullhorn.hideMenu = hideMenu;
    },
    setBullhornHideAddZoneButton: (state, { payload: hideAddZoneButton }) => {
      state.bullhorn.hideAddZoneButton = hideAddZoneButton;
    },
    setVideoPreview: (state, { payload: videoPreview }) => {
      state.videoPreview = videoPreview;
    },
  },
  sagas: {
    searchZonesDebouced: debounce(500)(async ({ payload }, dispatch) => {
      return await dispatch(searchZones(payload));
    }),
    warning: async ({ payload: text }, dispatch, getState) => {
      if (text) {
        Sentry.captureMessage(text + "");
      }
      dispatch(setWarning(text || "Something went wrong!"));
      await dispatch(waitFor((s) => !selectWarning(s)));
    },
    approve: async ({ payload: text }, dispatch, getState) => {
      dispatch(setApprove(text || "Are you sure?"));
      await dispatch(waitFor((s) => isBoolean(selectApprove(s))));
      const approved = selectApprove(getState(true));
      return { approved };
    },
    createToast: ({ payload: options }, dispatch, getState) => {
      const toast = {
        id: options.id || nanoid(),
        message: options.message || "",
        timeout: isNaN(options.timeout) ? 5000 : options.timeout,
        intent: options.intent || undefined,
        icon: options.icon || undefined,
        ...("className" in options && { className: options.className }),
      };
      dispatch(setToasts(getState().toasts.concat(toast)));
      return toast;
    },
    changeToast: ({ payload: { id, ...options } }, dispatch, getState) => {
      dispatch(
        setToasts(
          getState().toasts.map((toast) => {
            if (toast.id === id) {
              return {
                id,
                message:
                  options.message !== null
                    ? options.message || toast.message
                    : "",
                timeout:
                  options.timeout !== null
                    ? isNaN(options.timeout)
                      ? toast.timeout
                      : options.timeout
                    : 5000,
                intent:
                  options.intent !== null
                    ? options.intent || toast.intent
                    : undefined,
                icon:
                  options.icon !== null
                    ? options.icon || toast.icon
                    : undefined,
              };
            } else {
              return toast;
            }
          })
        )
      );
    },
    dismissToast: ({ payload: toastId }, dispatch, getState) => {
      dispatch(setToasts(getState().toasts.filter(({ id }) => id !== toastId)));
    },
    getUploadCredentials: async ({ payload: storageId }, dispatch) => {
      const accessToken = await dispatch(getToken());
      return await requestUploadCredentials(accessToken, storageId);
    },
    getUpdateCredentials: async ({ payload: nodeId }, dispatch) => {
      const accessToken = await dispatch(getToken());
      return await requestUpdateCredentials(accessToken, nodeId);
    },
    getDownloadCredentials: async (
      { payload: { fileId, intent } },
      dispatch
    ) => {
      const accessToken = await dispatch(getToken());
      return await requestDownloadCredentials(accessToken, fileId, intent);
    },
    enableWebPush: async ({}, dispatch, getState) => {
      const accessToken = await dispatch(getToken());
      const subscription = await setupWebPush(accessToken);
      if (subscription) {
        await dispatch(setWebPushStatus("granted"));
      } else {
        await dispatch(setWebPushStatus("denied"));
      }
    },
    setupNotificationChannel: (() => {
      let theChannel, setChannel;

      return async ({}, dispatch) => {
        if (theChannel) {
          return await theChannel;
        }
        let reject;
        theChannel = new Promise((succ, rej) => {
          setChannel = succ;
          reject = rej;
        });
        const { id: userId } = await dispatch(waitFor(selectIdentity));

        // Setup WebSocket
        const url = `${API_URL_PREFIX_NOTIFY_WS}/ws?userId=${userId}`;
        const socket = new WebSocket(url);
        socket.onopen = () => setChannel(socket);
        socket.onerror = (err) => reject(err);
        socket.onmessage = (event) => {
          const newData = JSON.parse(event.data);
          dispatch(addNotifications([newData]));
        };

        // Setup service worker
        const pushSupport = await hasWebPushSupport();
        if (pushSupport) {
          if (Notification.permission == "granted") {
            await dispatch(enableWebPush());
          } else {
            await dispatch(setWebPushStatus(Notification.permission));
          }
        } else {
          await dispatch(setWebPushStatus("nosupport"));
        }
      };
    })(),
    loadStickyNotifications: async ({}, dispatch, getState) => {
      const accessToken = await dispatch(getToken());
      const notifications = await fetchStickyNotifications(accessToken);
      dispatch(setStickyNotifications(notifications));
    },
    loadNotifications: async (
      { payload: { zoneId, more = false } = {} },
      dispatch,
      getState
    ) => {
      let params = selectNotificationPageParams(getState(true));
      params = { ...params };

      if (params.version && params.zoneId == zoneId && !more) {
        return;
      }

      if (!more) {
        delete params.lastId;
      }

      params.zoneId = zoneId;
      params.version++;

      dispatch(setNotificationPageParams(params));
      params = { ...params };

      if (!more) {
        dispatch(setNotifications([]));
      }

      const { version, ...fetchParams } = params;

      const accessToken = await dispatch(getToken());

      const events = await fetchNotifications(accessToken, fetchParams);

      if (version != selectNotificationPageParams(getState(true)).version) {
        return;
      }

      params.lastId = events.length ? events[events.length - 1].id : undefined;

      dispatch(setCanLoadMore(events.length != 0));
      dispatch(setNotificationPageParams(params));

      if (more) {
        dispatch(addNotifications(events));
      } else {
        dispatch(setNotifications(events));
      }
    },
  },
};

export const appSlice = createSlice(slice);

export const appSagas = slice.sagas;

export const {
  warning,
  approve,
  createToast,
  changeToast,
  dismissToast,
  enableWebPush,
  setupNotificationChannel,
  loadNotifications,
  loadStickyNotifications,
  getUploadCredentials,
  getUpdateCredentials,
  getDownloadCredentials,
  ///loadZones,
  ///expandNode,
  ///searchZones,
  ///searchEvents,
  searchZonesDebouced,
} = createSagaActions(slice);

export const {
  ///resetZonesPageParams,
  ///setNextPageParams,
  ///setCanLoadMore,
  setNotifications,
  addNotifications,
  setNotificationPageParams,
  setStickyNotifications,
  setDialog,
  setOverlay,
  setToasts,
  setWarning,
  setApprove,
  setWebPushStatus,
  setBullhornActive,
  setBullhornReady,
  setBullhornHideAddZoneButton,
  setBullhornHideMenu,
  setVideoPreview,
} = appSlice.actions;

export const selectDialog = (state) => state.app.dialog;
export const selectOverlay = (state) => state.app.overlay;
export const selectToasts = (state) => state.app.toasts;
export const selectWarning = (state) => state.app.warning;
export const selectApprove = (state) => state.app.approve;
export const selectWebPushStatus = (state) => state.app.webPushStatus;
export const selectNotificationPageParams = (state) =>
  state.app.notificationPageParams;
export const selectNotifications = (state) => state.app.notifications;
export const selectStickyNotifications = (state) =>
  state.app.stickyNotifications;
export const selectLegalNames = (state) => state.app.legalNames;
export const selectBullhornActive = (state) => state.app.bullhorn.active;
export const selectBullhornReady = (state) => state.app.bullhorn.ready;
export const selectBullhorn = (state) => state.app.bullhorn;
export const selectVideoPreview = (state) => state.app.videoPreview;

export default appSlice.reducer;

///export const selectZonesSearchTerm = state => state.app.zonesSearchTerm;
///export const selectCanLoadMore = state => state.app.canLoadMore;
