import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { selectCountChunk } from "features/transfer/transferSlice";
import VideoError from "./VideoError";
import styles from "./Preview.module.css";

const VIDEO_ERROR = "Your browser does not support the video tag.";
const MIME_TYPE = 'video/mp4; codecs="avc1.42E01E, mp4a.40.2"';

const Video = ({ theBlob, videoChunk, name, isMOV, isIphone }) => {
  const videoRef = useRef(null);
  const mediaSourceRef = useRef(null);
  const bufferRef = useRef(null);
  const hasBlob = useRef(false);

  const numberOfChunks = useSelector(selectCountChunk);
  const { total, count } = numberOfChunks;
  const lastChunkReceived = handleCountChunks();

  const [isError, setIsError] = useState(false);
  const [error, setError] = useState(null);
  const [moovChecked, setMoovChecked] = useState(false);

  function handleCountChunks() {
    const totalNumber = Number(total);
    const countNumber = Number(count);

    const lastChunkReceived = totalNumber !== 0 && totalNumber === countNumber;
    return lastChunkReceived;
  }

  const handleVideoError = (error) => {
    if (error) {
      setIsError(true);
      setError(error);
    }
  };

  const isMoovBoxPresent = (chunk) => {
    const uint8Array = new Uint8Array(chunk);
    const moovBoxSignature = [0x6d, 0x6f, 0x6f, 0x76]; // "moov" in hex
    const mvexBoxSignature = [0x6d, 0x76, 0x65, 0x78]; // "mvex" in hex

    // Find the position of the moov box
    const moovIndex = uint8Array.findIndex((_value, index) =>
      moovBoxSignature.every(
        (byte, offset) => uint8Array[index + offset] === byte
      )
    );

    if (moovIndex === -1) {
      return false; // moov box not found
    }

    // Extract the data starting from the moov box
    const moovChunk = uint8Array.subarray(moovIndex);

    // Check for the mvex box within the moov box
    const mvexIndex = moovChunk.findIndex((_value, index) =>
      mvexBoxSignature.every(
        (byte, offset) => moovChunk[index + offset] === byte
      )
    );

    return mvexIndex !== -1;
  };

  useEffect(() => {
    const videoElement = videoRef.current;

    const handleSourceOpenEvent = () => {
      try {
        bufferRef.current = mediaSourceRef.current.addSourceBuffer(MIME_TYPE);
      } catch (error) {
        handleVideoError(error);
      }
    };

    const handleVideoErrorEvent = () => {
      const error = videoElement && videoElement.error;
      if (error) {
        handleVideoError(error);
      } else {
        handleVideoError(error);
      }
    };

    const handleVideoAbortEvent = () => {
      handleVideoError({ code: 1 });
    };

    const handleVideoStalledEvent = () => {
      handleVideoError({ code: 2 });
    };

    if ("MediaSource" in window && MediaSource.isTypeSupported(MIME_TYPE)) {
      mediaSourceRef.current = new MediaSource();

      if (videoRef?.current) {
        videoElement.src = URL.createObjectURL(mediaSourceRef.current);

        mediaSourceRef.current.addEventListener(
          "sourceopen",
          handleSourceOpenEvent
        );
        videoElement.onerror = handleVideoErrorEvent;
        videoElement.onstalled = handleVideoStalledEvent;
        videoElement.onabort = handleVideoAbortEvent;
      }
    } else {
      handleVideoError({ code: 3 });
    }

    return () => {
      setError(null);
      setIsError(false);
      setMoovChecked(false);

      try {
        if (mediaSourceRef.current) {
          mediaSourceRef.current.removeEventListener(
            "sourceopen",
            handleSourceOpenEvent
          );
          mediaSourceRef.current = null;
        }
        if (bufferRef.current) {
          bufferRef.current = null;
        }
        if (videoElement) {
          videoElement.src = "";
          videoElement.onerror = null;
          videoElement.onstalled = null;
          videoElement.onabort = null;
        }
      } catch (error) {
        handleVideoError(error);
      }
    };
  }, [name]);

  useEffect(() => {
    if (
      bufferRef.current &&
      !bufferRef.current.updating &&
      mediaSourceRef.current &&
      mediaSourceRef.current.readyState === "open"
    ) {
      if (videoChunk) {
        try {
          if (!moovChecked) {
            if (isMoovBoxPresent(videoChunk)) {
              if (name.toLowerCase().endsWith(".mp4")) {
                setMoovChecked(true);
                bufferRef.current.appendBuffer(new Uint8Array(videoChunk));
              } else {
                setMoovChecked(true);
                handleVideoError({ code: 5 });
              }
            } else {
              if (name.toLowerCase().endsWith(".mp4")) {
                setMoovChecked(true);
                handleVideoError({ code: 4 });
              } else {
                setMoovChecked(true);
                handleVideoError({ code: 5 });
              }
            }
          } else {
            bufferRef.current.appendBuffer(new Uint8Array(videoChunk));
          }
        } catch (error) {
          handleVideoError(error);
        }
      }
    }
  }, [videoChunk, moovChecked, name]);

  useEffect(() => {
    try {
      if (
        bufferRef.current &&
        !bufferRef.current.updating &&
        mediaSourceRef.current &&
        mediaSourceRef.current.readyState === "open"
      ) {
        /*  The last chunk received must be TRUE due to the delay in transfer slice.
         This is done to prevent overloading the source buffer with chunks,
         even if the blob is completed before the last chunk is received. */

        if (lastChunkReceived && theBlob !== undefined) {
          mediaSourceRef.current.endOfStream();
        }
      }
    } catch (error) {
      handleVideoError({ code: 6 });
    }
  }, [lastChunkReceived, theBlob]);

  useEffect(() => {
    if (
      theBlob &&
      videoRef.current &&
      mediaSourceRef.current.readyState === "ended" &&
      !hasBlob.current
    ) {
      const videoElement = videoRef.current;

      const currentTime = videoElement.currentTime;

      videoElement.pause();
      videoElement.removeAttribute("src");
      videoElement.load();

      const blobUrl = URL.createObjectURL(theBlob);
      videoElement.src = blobUrl;

      videoElement.oncanplay = () => {
        videoElement.currentTime = currentTime;
        videoElement.play();
        videoElement.oncanplay = null; 
      };

      hasBlob.current = true;

      return () => {
        URL.revokeObjectURL(blobUrl);
        hasBlob.current = false;
      };
    }
  }, [theBlob, videoRef, mediaSourceRef]);

  return (
    <div className={styles.media}>
      <video ref={videoRef} className={styles.video} controls autoPlay muted>
        {VIDEO_ERROR}
      </video>
      {isError && theBlob === undefined && (
        <VideoError error={error} isMOV={isMOV} isIphone={isIphone} />
      )}
    </div>
  );
};

export default Video;
