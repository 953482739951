import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Invite from "./Invite";
import TeamCreate from "./TeamCreate.js";
import TeamExpire from "./TeamExpire.js";
import Attestation from "./Attestation.js";
import FolderCreate from "./FolderCreate.js";
import Rename from "./Rename.js";
import CategoryCreate from "./CategoryCreate.js";
import Restrict from "./Restrict.js";
import UserPicker from "./UserPicker.js";
import TreeActions from "./TreeActions.js";
import Esignature from "./Esignature.js";
import EsignatureSign from "./EsignatureSign.js";
import UpgradeTo2fa from "./UpgradeTo2fa.tsx";
import Video from "./Video.jsx";
import VideoInfo from "./VideoInfo.jsx";
import { setDialog, selectDialog } from "../../app/appSlice.js";

export const {
  INVITE,
  TEAM_CREATE,
  CATEGORY_CREATE,
  TEMPLATE_CREATE,
  FOLDER_CREATE,
  RENAME,
  TEAM_EXPIRE,
  ATTESTATION,
  ADD_MEMBER,
  RESTRICT,
  TREE_ACTIONS,
  ESIGNATURE,
  ESIGNATURE_SIGN,
  UPGRADE_2FA,
  VIDEO,
  VIDEO_INFO,
} = new Proxy(
  {},
  {
    get: (_, k) => {
      return k === "TEMPLATE_CREATE" ? "TEAM_CREATE" : k;
    },
  }
);

export default () => {
  const dispatch = useDispatch();
  const { dialog, ...args } = useSelector(selectDialog);
  const props = {
    ...args,
    dispatch,
    close: () => dispatch(setDialog()),
  };

  return (
    <>
      <Attestation active={dialog === ATTESTATION} {...props} />
      <Invite active={dialog === INVITE} {...props} />
      <TeamCreate active={dialog === TEAM_CREATE} {...props} />
      <TeamExpire active={dialog === TEAM_EXPIRE} {...props} />
      <FolderCreate active={dialog === FOLDER_CREATE} {...props} />
      <Rename active={dialog === RENAME} {...props} />
      <CategoryCreate active={dialog === CATEGORY_CREATE} {...props} />
      <UserPicker active={dialog === ADD_MEMBER} {...props} />
      <Restrict active={dialog === RESTRICT} {...props} />
      <TreeActions active={dialog === TREE_ACTIONS} {...props} />
      <Esignature active={dialog === ESIGNATURE} {...props} />
      <EsignatureSign active={dialog === ESIGNATURE_SIGN} {...props} />
      <UpgradeTo2fa active={dialog === UPGRADE_2FA} {...props} />
      <Video active={dialog === VIDEO} {...props} />
      <VideoInfo active={dialog === VIDEO_INFO} {...props} />
    </>
  );
};
