import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Dialog, Icon, IconSize, Intent, Tag } from "@blueprintjs/core";
import { setPrepareFile, PrepareFile } from "features/transfer/transferSlice";
import { selectIsMobile } from "features/layout/layoutSlice";
import { classNames } from "utils";
import styles from "./Dialogs.module.css";

export default ({ active, close, maxFileSize }) => {
  const dispatch = useDispatch();
  const isMobile = useSelector(selectIsMobile);

  const onCancel = () => {
    dispatch(setPrepareFile(PrepareFile.DENIED));
    close();
  };

  const onConfirm = () => {
    dispatch(setPrepareFile(PrepareFile.CONFIRMED));
    close();
  };

  return (
    <Dialog
      isOpen={active}
      onClose={onCancel}
      title={
        <>
          <Icon icon="video" size={IconSize.LARGE} />
          <span className={styles.dialogTitle} inert="true">
            Prepare Video for Preview
          </span>
          <Tag minimal round className={styles.tag} intent={Intent.SUCCESS}>
            Beta
          </Tag>
        </>
      }
      className={styles.dialog}
    >
      <div className={styles.previewBody}>
        <section>
          <h4>An unprepared video file has been detected in your upload.</h4>
          <p>
            To enable video preview, the file needs to be prepared and converted
            to a fragmented <span> MP4</span>. If your file is in
            <span> MOV </span>or<span> MP4 </span> format and you’d like to
            proceed with the conversion, click "Confirm". Otherwise, we'll keep
            the file in its original format.
          </p>
          <aside>
            <Icon
              icon="info-sign"
              size={IconSize.SMALL}
              intent={Intent.PRIMARY}
            />
            <p>The maximum preview file size is {maxFileSize} MB.</p>
          </aside>
        </section>
      </div>
      <div
        className={classNames(
          styles.buttonGroup,
          isMobile ? styles.mobile : ""
        )}
      >
        <Button onClick={onCancel}>Cancel</Button>
        <Button icon="upload" intent={Intent.SUCCESS} onClick={onConfirm}>
          Confirm
        </Button>
      </div>
    </Dialog>
  );
};
