import * as Sentry from "@sentry/browser";
import React, { Fragment, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Intent, Menu, MenuDivider, MenuItem, Tag } from "@blueprintjs/core";

import {
  setDialog,
  setOverlay,
  approve,
  selectVideoPreview,
} from "../../app/appSlice.js";
import { selectIdentity, inBullhorn } from "../../app/topSlice.js";

import appContext from "../../app/appContext.js";
import { appState } from "../../app/appState.js";

import {
  isPreviewableFileType,
  isSignableFileType,
} from "../overlays/Preview.js";

import { startDownload, startUpload } from "../transfer/transferSlice.js";
import { wrapStartDownload } from "../esignature/esignatureSlice.js";

import { dispatchToast } from "../toasts/Toasts.js";

import EsignatureIcon from "../esignature/esignatureIcon.js";
import { Blocked } from "../login/icons.tsx";

import useFileInput from "../../helpers/use-file-input";

import { ZONE } from "../../app/dictionary.js";

import {
  PANE_FILE,
  PANE_USERS,
  showPane,
} from "../../features/layout/layoutSlice.js";

import {
  CATEGORY_CREATE,
  RENAME as DIALOG_RENAME,
  FOLDER_CREATE as DIALOG_FOLDER_CREATE,
  TEAM_CREATE as DIALOG_TEAM_CREATE,
  TEAM_EXPIRE as DIALOG_TEAM_EXPIRE,
  TEMPLATE_CREATE as DIALOG_TEMPLATE_CREATE,
  INVITE as DIALOG_INVITE,
  ADD_MEMBER as DIALOG_ADD_MEMBER,
} from "../dialogs/Dialogs.js";

import { PREVIEW as OVERLAY_PREVIEW } from "../overlays/Overlays";

import {
  ATTESTATION_APPROVE,
  ATTESTATION_REJECT,
  ATTESTATION_REQUEST,
  FILE_CREATE,
  FILE_DELETE,
  FILE_DELETE_OWN,
  FILE_DOWNLOAD,
  FILE_INFO,
  FILE_MOVE,
  FILE_MOVE_OWN,
  FILE_PREVIEW,
  FOLDER_CREATE,
  FOLDER_DELETE,
  FOLDER_DELETE_OWN,
  FOLDER_MOVE,
  FOLDER_MOVE_OWN,
  FOLDER_RESTRICT,
  TEAM_CREATE,
  TEAM_EXPIRE,
  TEAM_RENAME,
  TEAM_TEAMMATE_ADD,
  TEAM_TEAMMATE_LEAVE,
  TEAM_TEAMMATE_REMOVE,
  TEAM_MUTE,
  TEMPLATE_CREATE,
  WORKSPACE_TEAMMATE_INVITE,
  ESIGNATURE_REQUEST,
  ESIGNATURE_APPROVE,
  ESIGNATURE_REJECT,
  WORKSPACE_LIST_TEAMS,
} from "../../app/permissions.js";

import {
  selectFolder,
  FILE_SYS_API,
} from "helpers/use-file-input/folderUpload.js";

import styles from "./Contexts.module.css";

import Mute from "../mute_team/Mute";

export const {
  ZONES_TEMPLATES,
  ZONES_TEMPLATE,
  ZONES_BACKGROUND,
  ZONES_CATEGORY,
  ZONES_ZONE,
  USERS_USER,
  TREE_BACKGROUND,
  TREE_FILE,
  TREE_FOLDER,
  ATTESTATION,
  ESIGNATURE,
  NODE_DELETE,
  NODE_MOVE,
  CUSTOM,
  USERS_BACKGROUND,
} = new Proxy({}, { get: (_, k) => k });

const {
  selectors: {
    selectPermissions,
    selectNodes,
    selectGraph,
    selectZones,
    selectGroups,
  },
  actions: { removeMember, removeCategory, removeNode, unsetCategory },
  derive: { getGroupsFromCurrentZone },
} = appState;

const DIVIDER = (before, after) =>
  before.props.children && after.props.children && <MenuDivider />;

const ZONES_IMPLICIT = ({ category, zone } = {}) => {
  const dispatch = useDispatch();
  const permissions = useSelector(selectPermissions);
  return (
    <>
      {permissions[TEAM_CREATE] && (
        <MenuItem
          icon="application"
          text={`create ${ZONE}`}
          onClick={() =>
            dispatch(setDialog({ dialog: DIALOG_TEAM_CREATE, category, zone }))
          }
        />
      )}
      <MenuItem
        icon="bookmark"
        text="create category"
        onClick={() => dispatch(setDialog({ dialog: CATEGORY_CREATE }))}
      />
      {permissions[TEMPLATE_CREATE] && (
        <MenuItem
          icon="applications"
          text="create template"
          onClick={() =>
            dispatch(
              setDialog({
                dialog: DIALOG_TEMPLATE_CREATE,
                template: true,
                zone,
              })
            )
          }
        />
      )}
    </>
  );
};

export default {
  ["RAW"]: ({ createComponent }) => createComponent(),
  [CUSTOM]: ({ actions, item }) => (
    <Menu>{actions.map((action) => S(action, item))}</Menu>
  ),
  [ZONES_BACKGROUND]: () => <Menu>{ZONES_IMPLICIT()}</Menu>,
  [ZONES_TEMPLATES]: () => <Menu>{ZONES_IMPLICIT()}</Menu>,
  [ZONES_TEMPLATE]: ({ zone: { id: zoneId, name }, userId }) => {
    const dispatch = useDispatch();
    const permissions = useSelector(selectPermissions);
    const implicit = ZONES_IMPLICIT({ zone: zoneId });
    const explicit = (
      <>
        {S(TEAM_TEAMMATE_LEAVE, { zone: { id: zoneId, name } })}
        {S(TEAM_MUTE, { zone: { id: zoneId, name } })}
      </>
    );

    return (
      <Menu>
        {explicit}
        {DIVIDER(implicit, explicit)}
        {implicit}
      </Menu>
    );
  },
  [ZONES_CATEGORY]: ({ id, name }) => {
    const dispatch = useDispatch();
    const permissions = useSelector(selectPermissions);
    const implicit = ZONES_IMPLICIT({ category: id });
    const explicit = (
      <>
        <MenuItem
          icon="edit"
          text="rename"
          onClick={async () => {
            dispatch(
              setDialog({
                dialog: DIALOG_RENAME,
                id,
                default: name,
                verb: "categories",
              })
            );
          }}
        />
        <MenuItem
          icon="trash"
          text="remove"
          onClick={async () => {
            const { approved } = await dispatch(approve());
            if (approved) {
              const toast = await dispatchToast(dispatch, {
                message: `Removing Category.`,
                icon: "SPINNER",
                intent: Intent.PRIMARY,
                timeout: 0,
              });
              try {
                await dispatch(removeCategory({ categoryId: id }));

                toast.replace({
                  message: `Category Removed.`,
                  icon: "CHECK",
                  intent: Intent.PRIMARY,
                });
              } catch (e) {
                Sentry.captureException(e);
                toast.replace({
                  message: `Failed to Remove Category.`,
                  icon: "warning-sign",
                  intent: Intent.DANGER,
                });
              }
            }
          }}
        />
      </>
    );
    return (
      <Menu>
        {explicit}
        {DIVIDER(implicit, explicit)}
        {implicit}
      </Menu>
    );
  },
  [ZONES_ZONE]: ({ zone: { id: zoneId, name }, userId }) => {
    const dispatch = useDispatch();
    const permissions = useSelector(selectPermissions);

    const divider = [
      TEAM_TEAMMATE_LEAVE,
      TEAM_EXPIRE,
      TEAM_RENAME,
      FILE_DOWNLOAD,
    ].some((p) => permissions[p]);

    const implicit = ZONES_IMPLICIT({ zone: zoneId });

    const explicit = (
      <>
        {[
          TEAM_TEAMMATE_LEAVE,
          TEAM_EXPIRE,
          TEAM_RENAME,
          FILE_DOWNLOAD,
          TEAM_MUTE,
        ].map((action) => S(action, { zone: { id: zoneId, name } }))}
      </>
    );

    return (
      <Menu>
        {explicit}
        {divider && DIVIDER(implicit, explicit)}
        {implicit}
      </Menu>
    );
  },
  [TREE_BACKGROUND]: () => {
    const dispatch = useDispatch();
    const permissions = useSelector(selectPermissions);
    return (
      <Menu>
        {[FOLDER_CREATE, FILE_CREATE, FOLDER_RESTRICT].map((action) =>
          S(action, {})
        )}
      </Menu>
    );
  },
  [TREE_FOLDER]: ({ nodeId }) => {
    const nodes = useSelector(selectNodes);
    const node = nodes[nodeId];

    return (
      <Menu>
        {[
          FOLDER_CREATE,
          FOLDER_DELETE,
          FOLDER_MOVE,
          FILE_INFO,
          FILE_CREATE,
          FILE_DOWNLOAD,
          FOLDER_RESTRICT,
        ].map((action) => S(action, node))}
      </Menu>
    );
  },
  [TREE_FILE]: ({ nodeId }) => {
    const nodes = useSelector(selectNodes);
    const node = nodes[nodeId];

    const dispatch = useDispatch();
    const item = node;

    const explicit = (
      <>
        {[
          FILE_PREVIEW,
          ESIGNATURE,
          ATTESTATION,
          FILE_DELETE,
          FILE_MOVE,
          FILE_CREATE,
          FILE_DOWNLOAD,
          FILE_INFO,
        ].map((action) => S(action, node))}
      </>
    );

    const implicit = <>{[FOLDER_CREATE].map((action) => S(action, node))}</>;

    return (
      <Menu>
        {explicit}
        {DIVIDER(implicit, explicit)}
        {implicit}
        {/*
        <MenuItem
          icon="font"
          text="edit"
          onClick={() =>
            dispatch(setOverlay({ overlay: OVERLAY_EDITOR, file: item }))
          }
        />*/}
      </Menu>
    );
  },
  [USERS_USER]: ({ zone, user }) => {
    const before = [TEAM_TEAMMATE_REMOVE]
      .map((action) => S(action, { zone, user }))
      .filter((_) => _);
    const after = [WORKSPACE_TEAMMATE_INVITE, TEAM_TEAMMATE_ADD]
      .map((action) => S(action, { zone }))
      .filter((_) => _);
    return (
      <Menu>
        {before}
        {!!before.length && !!after.length && <MenuDivider />}
        {after}
      </Menu>
    );
  },
  [USERS_BACKGROUND]: ({ zone }) => {
    return (
      <Menu>
        {[WORKSPACE_TEAMMATE_INVITE, TEAM_TEAMMATE_ADD].map((action) =>
          S(action, { zone })
        )}
      </Menu>
    );
  },
};

const S = (permission, item) => {
  const permissions = useSelector(selectPermissions);
  const { id: me } = useSelector(selectIdentity);
  const dispatch = useDispatch();
  const graph = useSelector(selectGraph);
  const isVideoPreview = useSelector(selectVideoPreview);
  const groups = getGroupsFromCurrentZone(graph);
  const { zoneId } = useContext(appContext);
  const virtualRootGroup = (graph.zones[zoneId] || { virtualRootGroup: null })
    .virtualRootGroup;

  if (permission === TEAM_TEAMMATE_LEAVE) {
    return (
      permissions[TEAM_TEAMMATE_LEAVE] &&
      Menues[TEAM_TEAMMATE_LEAVE](
        { zone: item.zone, user: { id: me } },
        dispatch
      )
    );
  }
  if (permission === TEAM_TEAMMATE_REMOVE) {
    if (item.user.id === me) {
      return (
        permissions[TEAM_TEAMMATE_LEAVE] &&
        Menues[TEAM_TEAMMATE_LEAVE](
          { zone: item.zone, user: { id: me } },
          dispatch
        )
      );
    } else if (!groups.some((grp) => grp.id === virtualRootGroup)) {
      //Not in virtual root group
      return false;
    } else {
      return (
        permissions[TEAM_TEAMMATE_REMOVE] &&
        Menues[TEAM_TEAMMATE_REMOVE](item, dispatch)
      );
    }
  }

  if (permission === ATTESTATION) {
    const canRequest = permissions[ATTESTATION_REQUEST];
    const canAttest =
      permissions[ATTESTATION_REJECT] || permissions[ATTESTATION_APPROVE];

    return (
      (canRequest || canAttest) &&
      Menues[ATTESTATION](item, dispatch, me, canRequest, canAttest, zoneId)
    );
  }

  if (permission === FOLDER_DELETE) {
    const deletePermission =
      item.creator === me ? FOLDER_DELETE_OWN : FOLDER_DELETE;
    if (!permissions[deletePermission]) {
      return false;
    }
    return Menues[NODE_DELETE](item, dispatch);
  }

  if (permission === FILE_DELETE) {
    const deletePermission =
      item.creator === me ? FILE_DELETE_OWN : FILE_DELETE;
    if (!permissions[deletePermission]) {
      return false;
    }
    return Menues[NODE_DELETE](item, dispatch);
  }

  if (permission === FILE_MOVE) {
    const deletePermission = item.creator === me ? FILE_MOVE_OWN : FILE_MOVE;
    if (!permissions[deletePermission]) {
      return false;
    }
    return Menues[NODE_MOVE](item, dispatch);
  }

  if (permission === FOLDER_MOVE) {
    const deletePermission =
      item.creator === me ? FOLDER_MOVE_OWN : FOLDER_MOVE;
    if (!permissions[deletePermission]) {
      return false;
    }
    return Menues[NODE_MOVE](item, dispatch);
  }

  if (permission === FILE_INFO) {
    return Menues[FILE_INFO](item, dispatch);
  }

  if (permission === FILE_PREVIEW) {
    if (!isVideoPreview && item.name.toLowerCase().endsWith(".mp4")) {
      return false;
    }

    if (!isPreviewableFileType(item)) {
      return false;
    }

    if (!permissions[FILE_PREVIEW]) {
      return false;
    }

    const isSignable = isSignableFileType(item);
    const hasSign = item.esignatures?.length;
    const canSign =
      permissions[ESIGNATURE_REJECT] || permissions[ESIGNATURE_APPROVE];

    const openSign = isSignable && hasSign && canSign;

    return Menues[FILE_PREVIEW](item, dispatch, openSign, zoneId);
  }

  if (permission === ESIGNATURE) {
    if (!isSignableFileType(item)) {
      return false;
    }

    const canRequest = permissions[ESIGNATURE_REQUEST];
    const canSign =
      permissions[ESIGNATURE_REJECT] || permissions[ESIGNATURE_APPROVE];

    return (
      (canRequest || canSign) &&
      Menues[ESIGNATURE](item, dispatch, me, canRequest, canSign, zoneId)
    );
  }

  if (permission === TEAM_MUTE) {
    return Menues[TEAM_MUTE](item, dispatch);
  }

  if (permission === WORKSPACE_TEAMMATE_INVITE) {
    if (inBullhorn && !permissions[WORKSPACE_LIST_TEAMS]) {
      return false;
    }
  }

  if (!permissions[permission]) {
    return false;
  }

  return Menues[permission](item, dispatch);
};

const Menues = {
  [FOLDER_RESTRICT]: (item, dispatch) => (
    <MenuItem
      key={`${FOLDER_RESTRICT}-${item.id}`}
      icon={<Blocked />}
      text="Secret Groups"
      onClick={() => dispatch(showPane(PANE_USERS))}
    />
  ),
  [FILE_INFO]: (item, dispatch) => (
    <MenuItem
      key={`${FILE_INFO}-${item.id}`}
      icon="info-sign"
      text="information"
      onClick={() => dispatch(showPane(PANE_FILE))}
    />
  ),
  ATTESTATION: (item, dispatch, me, canRequest, canAttest, zoneId) => {
    const showAttest = canRequest || canAttest;

    return (
      showAttest && (
        <MenuItem
          key={`${ATTESTATION}-${item.id}`}
          icon="endorsed"
          text="Attestation"
          onClick={() =>
            dispatch(
              setOverlay({
                overlay: OVERLAY_PREVIEW,
                file: item,
                zoneId,
              })
            )
          }
        />
      )
    );
  },
  ESIGNATURE: (item, dispatch, me, canRequest, canSign, zoneId) => {
    const hasEsign = item.esignatures?.length;

    let onClick = null;

    if (hasEsign) {
      onClick = () =>
        dispatch(setOverlay({ overlay: OVERLAY_PREVIEW, file: item, zoneId }));
    } else {
      onClick = () =>
        dispatch(
          setOverlay({
            overlay: OVERLAY_PREVIEW,
            file: item,
            zoneId,
            sign: true,
          })
        );
    }

    return (
      <MenuItem
        className={styles.esignature}
        key={`${ESIGNATURE}-${item.id}`}
        icon={<EsignatureIcon />}
        text="signature"
        onClick={onClick}
      />
    );
  },
  [TEAM_RENAME]: (item, dispatch) => (
    <MenuItem
      key={`${NODE_MOVE}-${item.id}`}
      icon="edit"
      text="rename"
      onClick={() =>
        dispatch(
          setDialog({
            dialog: DIALOG_RENAME,
            id: item.zone.id,
            default: item.zone.name,
            verb: "teams",
          })
        )
      }
    />
  ),
  [NODE_MOVE]: (item, dispatch) => (
    <MenuItem
      key={`${NODE_MOVE}-${item.id}`}
      icon="edit"
      text="rename"
      onClick={() =>
        dispatch(
          setDialog({
            dialog: DIALOG_RENAME,
            id: item.id,
            default: item.name,
            verb: "files",
          })
        )
      }
    />
  ),
  [NODE_DELETE]: (item, dispatch /*, me*/) => (
    <MenuItem
      key={`${NODE_DELETE}-${item.id}`}
      icon="delete"
      text="delete"
      onClick={async () => {
        if (!("parent" in item)) {
          await dispatchToast(dispatch, {
            message:
              `You can't delete that file ` +
              `since you don't have access to its parent folder`,
            icon: "warning-sign",
            intent: Intent.DANGER,
            timeout: 5000,
          });
          return;
        }
        const { approved } = await dispatch(approve());
        //const esignature = item.esignatures;
        if (approved) {
          const toast = dispatchToast(dispatch, {
            message: `Deleting.`,
            icon: "SPINNER",
            intent: Intent.PRIMARY,
            timeout: 0,
          });
          try {
            const { reason } = await dispatch(removeNode({ nodeId: item.id }));
            if (reason == "HANDLED") {
              toast.dismiss();
            } else if (reason) {
              throw new Error(reason);
            } else {
              toast.replace({
                message: `Successfully Deleted.`,
                icon: "CHECK",
                intent: Intent.PRIMARY,
              });
              //if (esignature.length === 1) {
              //  console.log(esignature[0].signicatId);
              //  await dispatch(
              //    cancelSignicatDocument(
              //      esignature[0].signicatId,
              //      esignature[0].id,
              //      "delete",
              //      item.name,
              //      "noorg",
              //      me
              //    )
              //  );
              //}
            }
          } catch (e) {
            Sentry.captureException(e);
            toast.replace({
              message: `Failed to Delete.`,
              icon: "warning-sign",
              intent: Intent.DANGER,
            });
          }
        }
      }}
    />
  ),
  [FILE_PREVIEW]: (item, dispatch, openSign, zoneId) => (
    <MenuItem
      className={styles.primary}
      key={`${FILE_PREVIEW}-${item.id}`}
      /*   icon="eye-open" */
      icon="document-open"
      text="preview"
      onClick={() => {
        //if (openSign) {
        //  dispatch(setOverlay({ overlay: OVERLAY_ESIGNATURE, file: item }));
        //} else {
        dispatch(setOverlay({ overlay: OVERLAY_PREVIEW, file: item, zoneId }));
        //}
      }}
    />
  ),
  [FILE_CREATE]: (item, dispatch) => {
    const context = useContext(appContext);
    const { zoneId } = context;
    const isVideoPreview = useSelector(selectVideoPreview);
    let folderId, groupId;
    if (Object.keys(item).length === 0) {
      ({ folderId, groupId } = context);
    } else if ("storageId" in item) {
      folderId = item.parent;
      groupId = item.group;
    } else {
      folderId = item.id;
      groupId = item.group;
    }
    const selectFile = useFileInput();
    const selectVideoFile = useFileInput({ video: true });

    const handlePickFolder = async () => {
      const files = await selectFolder();
      if (files instanceof Error) {
        return;
      }
      dispatch(startUpload(files, groupId, folderId, zoneId));
    };

    return (
      <Fragment key={`${FILE_CREATE}-${item.id}`}>
        <MenuItem
          icon="upload"
          text="Upload File"
          onClick={() => {
            selectFile((files) => {
              if (files) {
                dispatch(startUpload(files, groupId, folderId, zoneId));
              }
            });
          }}
        />
        {isVideoPreview && (
          <MenuItem
            icon="video"
            text={
              <>
                Upload Video
                <Tag
                  className={styles.beta}
                  minimal
                  round
                  intent={Intent.SUCCESS}
                >
                  Beta
                </Tag>
              </>
            }
            onClick={() => {
              selectVideoFile((files) => {
                if (files) {
                  dispatch(startUpload(files, groupId, folderId, zoneId));
                }
              });
            }}
          />
        )}
        {FILE_SYS_API && (
          <MenuItem
            icon="folder-open"
            text="Upload Folder"
            onClick={() => handlePickFolder()}
          />
        )}
      </Fragment>
    );
  },
  [FILE_DOWNLOAD]: (item, dispatch) => {
    const zoneToDownload = item.zone;
    if (zoneToDownload) {
      const nodes = useSelector(selectNodes);
      const groups = useSelector(selectGroups);
      const zones = useSelector(selectZones);
      const zone = zones[item.zone.id]; // @ TypeError: Cannot read properties of undefined (reading 'rootGroup') >>> ???
      const group = groups[zone.rootGroup]; // @ TypeError: Cannot read properties of undefined (reading 'rootGroup') >>> ???
      if (!group) return null;
      const nodeId = group.rootFile;
      // TODO when not part of team ie all folders are graft branches, apperenyly one does not have the rootGroups rootFile loaded, causing a crash
      item = nodes[nodeId];
    }

    // HOW can item.id crash below when item.zone do not crash above ??? Because item is first an object, but item = nodes[nodeId] is not!

    return (
      <MenuItem
        key={`${FILE_DOWNLOAD}-${item.id}`} // @ TypeError: Cannot read properties of undefined (reading 'id') >>> item.id
        icon="download"
        text="download"
        //onClick={() => dispatch(startDownload(item))}
        onClick={() => dispatch(wrapStartDownload(startDownload, item))} // @ TypeError: e is undefined >>> ???
      />
    );
  },
  [FOLDER_CREATE]: (item, dispatch) => (
    <MenuItem
      key={`${FOLDER_CREATE}-${item.id}`}
      icon="folder-new"
      text="create folder"
      onClick={() => dispatch(setDialog({ dialog: DIALOG_FOLDER_CREATE }))}
    />
  ),
  [TEAM_TEAMMATE_REMOVE]: (item, dispatch) => (
    <MenuItem
      key={`${TEAM_TEAMMATE_REMOVE}-${item.user.id}-${item.zone.id}`}
      icon="log-out"
      text={"Remove from team"}
      onClick={async () => {
        const { approved } = await dispatch(approve());
        if (approved) {
          const toast = await dispatchToast(dispatch, {
            message: `Removing User.`,
            icon: "SPINNER",
            intent: Intent.PRIMARY,
            timeout: 0,
          });
          try {
            const { reason } = await dispatch(
              removeMember({ zoneId: item.zone.id, userId: item.user.id })
            );
            if (reason == "HANDLED") {
              toast.dismiss();
            } else if (reason) {
              throw new Error(reason);
            } else {
              toast.replace({
                message: `Removed User.`,
                icon: "CHECK",
                intent: Intent.PRIMARY,
              });
            }
          } catch (e) {
            Sentry.captureException(e);
            toast.replace({
              message: `Failed to Remove User.`,
              icon: "warning-sign",
              intent: Intent.DANGER,
            });
          }
        }
      }}
    />
  ),
  [TEAM_TEAMMATE_LEAVE]: (item, dispatch) => (
    <MenuItem
      key={`${TEAM_TEAMMATE_LEAVE}-${item.zone.id}`}
      icon="log-out"
      text={"Leave team"}
      onClick={async () => {
        const { approved } = await dispatch(approve());

        if (approved) {
          const toast = await dispatchToast(dispatch, {
            message: `Leaving.`,
            icon: "SPINNER",
            intent: Intent.PRIMARY,
            timeout: 0,
          });
          try {
            dispatch(unsetCategory({ zoneId: item.zone.id }));
            const { reason } = await dispatch(
              removeMember({ zoneId: item.zone.id, userId: item.user.id })
            );
            if (reason == "HANDLED") {
              toast.dismiss();
            } else if (reason == "ADVERSE_EFFECT") {
              await dispatch(
                approve([
                  "Wait!",
                  "Make sure someone else is still around before you leave.",
                  true,
                ])
              );
              toast.dismiss();
            } else if (reason) {
              throw new Error(reason);
            } else {
              toast.replace({
                message: `Successfully Left.`,
                icon: "CHECK",
                intent: Intent.PRIMARY,
              });
            }
          } catch (e) {
            Sentry.captureException(e);
            toast.replace({
              message: e.message || "Something went wrong, please try again.",
              icon: "warning-sign",
              intent: Intent.DANGER,
            });
          }
        }
      }}
    />
  ),
  [WORKSPACE_TEAMMATE_INVITE]: (item, dispatch) => (
    <MenuItem
      key={`${WORKSPACE_TEAMMATE_INVITE}-${item.zone.id}`}
      text="Invite User"
      onClick={() => {
        dispatch(
          setDialog({
            dialog: DIALOG_INVITE,
            zoneId: item.zone.id,
          })
        );
      }}
      icon="envelope"
    />
  ),
  [TEAM_TEAMMATE_ADD]: (item, dispatch) => (
    <MenuItem
      key={`${TEAM_TEAMMATE_ADD}-${item.zone.id}`}
      text="Add User"
      icon="new-person"
      onClick={() => {
        dispatch(
          setDialog({
            dialog: DIALOG_ADD_MEMBER,
            zoneId: item.zone.id,
          })
        );
      }}
    />
  ),
  [TEAM_EXPIRE]: (item, dispatch) => (
    <MenuItem
      key={`${TEAM_EXPIRE}-${item.zone.id}`}
      icon="time"
      text="Delete files at..."
      onClick={() =>
        dispatch(setDialog({ dialog: DIALOG_TEAM_EXPIRE, zone: item.zone.id }))
      }
    />
  ),
  [TEAM_MUTE]: (item) => (
    <Mute item={item} key={`${TEAM_MUTE}-${item.zone.id}`} />
  ),
};
