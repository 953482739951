import styles from "./Preview.module.css";
import { Progress } from "features/viewer/Viewer";

export default ({ theBlob, name }) => {
  let url = "";

  if (theBlob instanceof Blob) {
    url = URL.createObjectURL(theBlob);
  }

  return (
    <div className={styles.media}>
      {theBlob ? (
        <img
          src={url}
          className={styles.image}
          alt={name ? name : "image cannot be displayed"}
        />
      ) : (
        <div>
          <Progress />
        </div>
      )}
    </div>
  );
};