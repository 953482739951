import React, { useRef, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Icon, Spinner, SpinnerSize } from "@blueprintjs/core";

import EsignatureIcon from "../esignature/esignatureIcon";

import { Skeleton } from "./Skeleton";

import MozillaViewer from "./Mozilla";

import { createBackend as createPDFBackend } from "./pdfBackend";
import { createBackend as createDocBackend } from "./docBackend";
import { createBackend as createSheetBackend } from "./sheetBackend";
import { createBackend as createSlideBackend } from "./slideBackend";

import { setOverlay } from "../../app/appSlice.js";
import { PREVIEW as OVERLAY_PREVIEW } from "../overlays/Overlays";

import Image from "features/overlays/Image";
import Video from "features/overlays/Video";
import VideoError from "features/overlays/VideoError";

import { IMAGE, VIDEO } from "features/overlays/Preview";

import { selectVideoPreview } from "../../app/appSlice.js";

import {
  selectCountChunk,
  selectSortedList,
  setAbortStream,
} from "features/transfer/transferSlice";

import {
  fromFile,
  toBase64,
  toChecksum,
  getFileExtension,
  getMimeTypeFromExt,
  getTypeFromMime,
  isViewableFileExt,
  isSignableFileExt,
} from "./utils";

import styles from "./Viewer.module.css";

export {
  getFileExtension,
  getMimeTypeFromExt,
  getTypeFromMime,
  isViewableFileExt,
  isSignableFileExt,
};

const createDefaultBackend = () => {};

const chooseBackend = (type) => {
  let createBackend;
  if (type === "pdf") {
    createBackend = createPDFBackend;
  } else if (type === "doc") {
    createBackend = createDocBackend;
  } else if (type === "sheet") {
    createBackend = createSheetBackend;
  } else if (type === "slide") {
    createBackend = createSlideBackend;
  } else {
    createBackend = createDefaultBackend;
  }
  return createBackend;
};

export const fromBlob = async (blob) => {
  const mime = blob?.type || "";
  const type = getTypeFromMime(mime);
  const bytes = await fromFile(blob);
  const checksum = ""; //await toBase64(await toChecksum(bytes));
  return [type, bytes, checksum, mime];
};

export const Viewer = ({
  type,
  blob,
  mime,
  name,
  Widget = null,
  widgetProps = {},
  nav = [],
  nodeId,
  zoneId,
  onClose,
  videoChunk,
  fileLoading,
}) => {
  const refElement = useRef(null);
  const refBackend = useRef(null);
  const refPrev = useRef(null);
  const [fileIndex, setFileIndex] = useState(0);

  const noFoldersList = useSelector(selectSortedList);
  const isVideoPreview = useSelector(selectVideoPreview);

  const targetNode = noFoldersList.find((node) => node.id === nodeId);
  const targetParentId = targetNode ? targetNode.parent : null;
  const sortedList = noFoldersList.filter(
    (node) => node.parent === targetParentId
  );

  const dispatch = useDispatch();

  const setRef = useCallback(
    (element) => {
      if (refBackend.current) {
        refBackend.current.destroy();
      }
      refElement.current = element;
      if (element && blob) {
        refBackend.current = chooseBackend(type)(element, blob, mime);
      }
    },
    [blob]
  );

  useEffect(() => {
    if (!blob) {
      if (refBackend.current) {
        refBackend.current.destroy();
      }
    } else if (refPrev.current && refPrev.current !== blob) {
      refPrev.current = blob;
      if (refElement.current) {
        if (refBackend.current) {
          refBackend.current.destroy();
        }
        refBackend.current = chooseBackend(type)(
          refElement.current,
          blob,
          mime
        );
      }
    }
  }, [blob]);

  useEffect(() => {
    try {
      if (sortedList && sortedList.some((item) => item.id === nodeId)) {
        const index = sortedList.findIndex((item) => item.id === nodeId);
        setFileIndex(index);
      } else {
        throw new Error("Node ID not found in sorted list");
      }
    } catch (error) {
      console.error(error);
    }
  }, [nodeId, sortedList]);

  const goToNextDocument = useCallback(() => {
    const nextIndex = fileIndex + 1;
    if (fileIndex + 1 < sortedList.length) {
      const item = sortedList[nextIndex];
      setFileIndex(nextIndex);
      onClose(false);
      dispatch(setOverlay({ overlay: OVERLAY_PREVIEW, file: item, zoneId }));
    }
  }, [fileIndex, sortedList, onClose, dispatch, zoneId]);

  const goToPrevDocument = useCallback(() => {
    const prevIndex = fileIndex - 1;
    if (prevIndex >= 0) {
      const item = sortedList[prevIndex];
      setFileIndex(prevIndex);
      onClose(false);
      dispatch(setOverlay({ overlay: OVERLAY_PREVIEW, file: item, zoneId }));
    }
  }, [fileIndex, sortedList, onClose, dispatch, zoneId]);

  let content;

  const updateNavItems = (nav, refBackend) => {
    for (const item of nav) {
      if (item.icon === "esignature") {
        item.icon = <EsignatureIcon />;
      } else if (item.icon === "endorsed") {
        item.icon = <Icon icon={item.icon} />;
      } else if (item.type === "zoom") {
        item.onIn = () => refBackend.current.zoomIn();
        item.onOut = () => refBackend.current.zoomOut();
      } else if (item.type === "file") {
        item.nextFile = () => goToNextDocument();
        item.prevFile = () => goToPrevDocument();
      } else if (item.type === "nav") {
        item.onPrev = () => refBackend.current.navPrev();
        item.onNext = () => refBackend.current.navNext();
      } else if (item.type === "end") {
        item.onEnd = () => refBackend.current.navEnd();
      }
    }
  };

  const isMedia = type === IMAGE || type === VIDEO;
  const isMOV = name?.toLowerCase()?.endsWith(".mov") && isVideoPreview;
  const isIphone = /iPhone/.test(navigator.userAgent) && isVideoPreview;

  if (blob && !isMedia) {
    updateNavItems(nav, refBackend);
    if (type === "pdf") {
      content = (
        <ViewerBody
          name={name}
          backend={refBackend.current}
          nav={nav.filter((item) => item.type !== "nav")}
          Widget={Widget}
          widgetProps={widgetProps}
          fileLoading={fileLoading}
        >
          <PdfViewer setRef={setRef} />
        </ViewerBody>
      );
    } else if (type === "doc") {
      content = (
        <ViewerBody
          name={name}
          backend={refBackend.current}
          nav={nav.filter((item) => item.type !== "nav" && item.type !== "end")}
          Widget={Widget}
          widgetProps={widgetProps}
          fileLoading={fileLoading}
        >
          <DocViewer setRef={setRef} />
        </ViewerBody>
      );
    } else if (type === "sheet") {
      content = (
        <ViewerBody
          name={name}
          backend={refBackend.current}
          nav={nav.filter((item) => item.type !== "end")}
          Widget={Widget}
          widgetProps={widgetProps}
          fileLoading={fileLoading}
        >
          <SheetViewer setRef={setRef} />
        </ViewerBody>
      );
    } else if (type === "slide") {
      content = (
        <ViewerBody
          name={name}
          backend={refBackend.current}
          nav={nav.filter((item) => item.type !== "end")}
          Widget={Widget}
          widgetProps={widgetProps}
          fileLoading={fileLoading}
        >
          <SlideViewer setRef={setRef} />
        </ViewerBody>
      );
    } else if (type === "unknown") {
      content = (
        <ViewerBody
          name={name}
          nav={nav.filter((item) => item.type !== "end")}
          isMOV={isMOV}
          fileLoading={fileLoading}
          isIphone={isIphone}
          isVideoPreview={isVideoPreview}
        ></ViewerBody>
      );
    } else {
      content = (
        <ViewerBody>
          <AViewer />
        </ViewerBody>
      );
    }
  } else if (type === "image") {
    updateNavItems(nav, refBackend);
    content = (
      <ViewerBody
        name={name}
        backend={refBackend.current}
        nav={nav}
        Widget={Widget}
        widgetProps={widgetProps}
        theBlob={blob}
        media={type}
        fileLoading={fileLoading}
      >
        <Image theBlob={blob} name={name} />
      </ViewerBody>
    );
  } else if (type === "video") {
    updateNavItems(nav, refBackend);
    content = (
      <ViewerBody
        name={name}
        backend={refBackend.current}
        nav={nav}
        Widget={Widget}
        widgetProps={widgetProps}
        theBlob={blob}
        media={type}
        isMOV={isMOV}
        fileLoading={fileLoading}
        isIphone={isIphone}
      >
        <>
          {fileLoading && blob === undefined ? (
            <div className={styles.Info}>
              <Spinner size={SpinnerSize.LARGE} />
            </div>
          ) : !isVideoPreview ? (
            <div className={styles.Media}>
              <VideoError error={{ code: 5 }} />
            </div>
          ) : isIphone ? (
            <div className={styles.Media}>
              <VideoError
                error={{ code: 7 }}
                isMOV={isMOV}
                isIphone={isIphone}
              />
            </div>
          ) : (
            <Video
              theBlob={blob}
              videoChunk={videoChunk}
              name={name}
              isMOV={isMOV}
              isIphone={isIphone}
            />
          )}
        </>
      </ViewerBody>
    );
  } else if (type === "unknown") {
    updateNavItems(nav, refBackend);
    content = (
      <ViewerBody
        name={name}
        nav={nav.filter((item) => item.type !== "end")}
        isMOV={isMOV}
        fileLoading={fileLoading}
        isIphone={isIphone}
      ></ViewerBody>
    );
  } else {
    content = <Skeleton />;
  }

  return content;
};

const ViewerBody = ({
  name,
  backend,
  children,
  nav = [],
  Widget = null,
  widgetProps = {},
  theBlob,
  media = "",
  isMOV = false,
  fileLoading,
  isIphone,
}) => {
  let row = [];
  for (const item of nav) {
    if (item.type === "expander") {
      row.push(<div className={styles.Expander} />);
    } else if (item.type === "title") {
      row.push(<div className={styles.Title}>{name}</div>);
    } else if (item.type === "button") {
      let className = styles.Button;
      if (item.primary) {
        if (media === VIDEO || media === IMAGE) {
          className +=
            typeof theBlob !== "undefined"
              ? ` ${styles.Primary}`
              : ` ${styles.Disabled}`;
        } else {
          className += " " + styles.Primary;
        }
      } else if (item.warning) {
        className += " " + styles.Warning;
      } else if (item.attestation) {
        className += " " + styles.Attestation;
      }
      row.push(
        <div className={className} onClick={() => item.onClick?.()}>
          {typeof item.icon === "string" ? (
            <Icon icon={item.icon} />
          ) : (
            item.icon
          )}
          <div>{item.text}</div>
        </div>
      );
    } else if (item.type === "file") {
      row.push(
        <div className={styles.Nav}>
          <>
            <div onClick={() => !fileLoading && item.prevFile?.()}>
              <Icon icon="arrow-left" />
            </div>
            <div onClick={() => !fileLoading && item.nextFile?.()}>
              <Icon icon="arrow-right" />
            </div>
          </>
        </div>
      );
    } else if (item.type === "zoom") {
      row.push(
        <div className={styles.Nav + " " + styles.Zoom}>
          <div onClick={() => item.onOut?.()}>
            <Icon icon="minus" />
          </div>
          <div onClick={() => item.onIn?.()}>
            <Icon icon="plus" />
          </div>
        </div>
      );
    } else if (item.type === "nav") {
      row.push(
        <div className={styles.Nav}>
          <div onClick={() => item.onPrev?.()}>
            <Icon icon="chevron-left" />
          </div>
          <div onClick={() => item.onNext?.()}>
            <Icon icon="chevron-right" />
          </div>
        </div>
      );
    } else if (item.type === "end") {
      row.push(
        <div className={styles.Button} onClick={() => item.onEnd?.()}>
          <Icon icon="flow-end" />
        </div>
      );
    }
  }
  row = row.map((row, i) => React.cloneElement(row, { key: i }));
  return (
    <div className={styles.Outer}>
      <div className={styles.Navbar}>{row}</div>
      <div className={styles.Inner}>
        {Widget && (
          <>
            <input id="1UzhThFd" type="checkbox" className={styles.Toggle} />
            <label htmlFor="1UzhThFd">
              <Icon icon="arrow-left" />
              <Icon icon="menu" />
              <div></div>
            </label>
            <div className={styles.Sidebar}>
              <div onClick={() => widgetProps?.onClose?.()}>
                <Icon icon="small-cross" />
                <div>close</div>
              </div>
              <Widget
                goToLastPage={() => backend?.navEnd?.()}
                {...widgetProps}
              />
            </div>
          </>
        )}
        <div className={styles.Main}>
          {children ? (
            children
          ) : (
            <div className={styles.Info}>
              <VideoError
                error={{ code: 5 }}
                isMOV={isMOV}
                isIphone={isIphone}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const AViewer = () => <div className={styles.AViewer} />;

const PdfViewer = ({ setRef }) => (
  <MozillaViewer>
    <div ref={setRef} />
  </MozillaViewer>
);

const DocViewer = ({ setRef }) => (
  <div ref={setRef} className={styles.NSon100p} />
);

const SheetViewer = ({ setRef }) => (
  <div ref={setRef} className={styles.NSon100p} />
);

const SlideViewer = ({ setRef }) => (
  <div ref={setRef} className={styles.NSon100p} />
);

export const Progress = () => {
  const countChunk = useSelector(selectCountChunk);

  const calculatePercentage = (countChunk) => {
    const count = countChunk?.count || 0;
    const total = countChunk?.total || 1;
    const percentage = (count / total) * 100;
    return Math.round(percentage);
  };

  return (
    <div>
      <Spinner size={SpinnerSize.LARGE} />
      <span className={styles.Progress}>
        {calculatePercentage(countChunk)} / 100%
      </span>
    </div>
  );
};
