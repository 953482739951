export const fromFile = (file) =>
  new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.addEventListener("error", (ev) => reject(ev.target.error));
    fileReader.addEventListener("load", (ev) =>
      resolve(new Uint8Array(ev.target.result))
    );
    fileReader.readAsArrayBuffer(file);
  });

export const toChecksum = async (buf) => {
  return new Uint8Array(await crypto.subtle.digest("SHA-256", buf));
};

export const toBase64 = (uint8Array) =>
  new Promise((resolve, reject) => {
    try {
      const blob = new Blob([uint8Array], { type: "application/octet-binary" });
      const fileReader = new FileReader();
      fileReader.addEventListener("error", (ev) => reject(ev.target.error));
      fileReader.addEventListener("load", () =>
        resolve(fileReader.result.substr(fileReader.result.indexOf(",") + 1))
      );
      fileReader.readAsDataURL(blob);
    } catch (e) {
      reject(e);
    }
  });

export const isViewableFileExt = (ext) =>
  [
    "pdf",
    "docx",
    "xlsx",
    "pptx",
    "jpg",
    "jpeg",
    "JPG",
    "png",
    "gif",
    "bmp",
    "webp",
    "mp4",
    "MP4",
  ].includes(ext);

export const isSignableFileExt = (ext) => ["pdf"].includes(ext);

export const getFileExtension = (name) => name.split(".").pop();

export const getMimeTypeFromExt = (ext) => {
  switch (ext) {
    case "pdf":
      return "application/pdf";
    case "docx":
      return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
    case "xlsx":
      return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    case "pptx":
      return "application/vnd.openxmlformats-officedocument.presentationml.presentation";
    case "mp4":
    case "MP4":
      return "video/mp4";
    case "jpg":
    case "jpeg":
    case "JPG":
      return "image/jpeg";
    case "png":
      return "image/png";
    case "gif":
      return "image/gif";
    case "bmp":
      return "image/bmp";
    case "webp":
      return "image/webp";
    default:
      return "application/octet-stream";
  }
};

export const getTypeFromMime = (mimeType) => {
  switch (mimeType) {
    case "application/pdf":
      return "pdf";
    case "application/msword":
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.template":
      return "doc";
    case "application/vnd.ms-excel":
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.template":
      return "sheet";
    case "application/vnd.ms-powerpoint":
    case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
    case "application/vnd.openxmlformats-officedocument.presentationml.template":
    case "application/vnd.openxmlformats-officedocument.presentationml.slideshow":
      return "slide";
    case "video/mp4":
      return "video";
    case "image/jpeg":
    case "image/png":
    case "image/gif":
    case "image/bmp":
    case "image/webp":
      return "image";
    default:
      return "unknown";
  }
};

export const loadScript = (src, callback) => {
  let s = document.getElementById(src);

  if (s) {
    if (s.dataset.loaded) {
      callback();
    } else {
      s.onload = () => {
        s.dataset.loaded = true;
        callback();
      };
    }
  } else {
    s = document.createElement("script");
    s.type = "text/javascript";
    s.src = `/${src}`;
    s.id = src;
    s.onload = () => {
      s.dataset.loaded = true;
      callback();
    };
    document.body.appendChild(s);
  }
};
