import { createSlice } from "@reduxjs/toolkit";

import S3 from "aws-sdk/clients/s3";

import { FFmpeg } from "@ffmpeg/ffmpeg";

import { toBlobURL, fetchFile } from "@ffmpeg/util";

import MP4Box from "mp4box";

import * as Sentry from "@sentry/browser";

import { TransformStream as TransformStream0 } from "web-streams-polyfill/ponyfill";

import { Writer as ZipStream } from "@transcend-io/conflux";

import {
  uid,
  values,
  filterObj,
  jwtPeek,
  isString,
  isArray,
  isObject,
} from "utils";

import { Data } from "hyker-crypto";

import createApiClient from "api-client";

import { API_URL_PREFIX, API_URL_PREFIX_AUTH } from "../../config.js";

import { FILE_DOWNLOAD } from "../../app/permissions.js";

import { getToken } from "../../app/topSlice.js";

import { showPane, PANE_TRANSFER } from "../../features/layout/layoutSlice.js";

import {
  getUploadCredentials,
  getUpdateCredentials,
  setDialog,
} from "../../app/appSlice";

import { requestDownloadCredentials } from "../../app/realworld";

import { getContext } from "../../app/topSlice.js";

import { trustKit } from "../../app/trustKit.js";

import { appState } from "../../app/appState.js";

//import { download as downloadInServiceWorker } from "../../service-worker-client";

import { dispatchToast } from "../toasts/Toasts.js";

import { Intent } from "@blueprintjs/core";

import { createFileEntryItFactory } from "helpers/use-file-input/folderUpload.js";

import { VIDEO } from "../dialogs/Dialogs.js";

import { uuid } from "utils";

const {
  auxiliary: { getSymKey, cachePublicKeys },
} = trustKit;

const {
  actions: { createNode, uploadNode },
} = appState;

const DRY = false;

const ACTION_READ = "read";

const PLAINTEXT_CHUNK_SIZE = 1024 * 1024 * 1;
const PLAINTEXT_CHUNKS_PER_UPLOAD_CHUNK = 5;
const UPLOAD_CHUNK_SIZE =
  PLAINTEXT_CHUNKS_PER_UPLOAD_CHUNK * PLAINTEXT_CHUNK_SIZE;

export const PrepareFile = {
  NOT_STARTED: "NOT_STARTED",
  CONFIRMED: "CONFIRMED",
  DENIED: "DENIED",
};

class UnableToGetContentLengthError extends Error {
  constructor(message) {
    super(message);
  }
}

const calcSpeed = (size, progress, t0) => {
  if (!progress) {
    return { hours: -1, mins: -1, secs: -1 };
  }
  const done = size * progress;
  const left = size - done;
  const duration = Math.max(1, new Date() - t0);
  const bps = done / duration || 1; // Avoid division by zero if empty file
  const eta = Math.ceil(left / bps / 5000) * 5;
  const hours = Math.floor(eta / 3600);
  const mins = Math.floor((eta - hours * 3600) / 60);
  const secs = Math.ceil(eta - hours * 3600 - mins * 60);
  return { hours, mins, secs, bps };
};

const calcSummary = (map, summary) => {
  summary = summary || { map: {} };

  map = {
    ...summary.map,
    ...filterObj(map, (id, { progress }) => id in summary.map || progress < 1),
  };

  const all = values(map);
  const total = all.length;
  const name = total == 1 ? all[0].name : "";

  let sub = 0;
  let sum = 0;
  let completed = 0;

  for (const { size, progress } of all) {
    sum += size;
    sub += size * progress;
    if (1 <= progress) {
      completed++;
    }
  }

  if (total == completed) {
    return null;
  }

  const size = sum;
  sum = sum || 1; // Avoid division by zero if empty file
  const progress = sub / sum;
  const left = sum - sub;
  const bps = all.reduce((a, c) => a + (c.bps ?? 0), 0) || 1; // Avoid division by zero if empty file
  const eta = Math.ceil(left / bps / 5000) * 5;
  const hours = Math.floor(eta / 3600);
  const mins = Math.floor((eta - hours * 3600) / 60);
  const secs = Math.ceil(eta - hours * 3600 - mins * 60);

  return { map, name, size, progress, completed, total, hours, mins, secs };
};

export const transferSlice = createSlice({
  name: "transfer",
  initialState: {
    uploading: false,
    summary: null,
    progress: {},
    abortStream: false,
    countChunk: { count: 0, total: 0 },
    sortedList: [],
    chunkIndex: 0,
    prepareFile: PrepareFile.NOT_STARTED,
  },
  reducers: {
    setUploading: (state, { payload: uploading }) => {
      state.uploading = !!uploading;
    },
    setProgress: (state, { payload: { file, progress, error } }) => {
      const { id, size } = file;
      const t0 = state.progress[id]?.t0 ?? +new Date();
      const speed = calcSpeed(size, progress, t0);
      state.progress[id] = {
        t0,
        ...file,
        progress,
        ...speed,
        ...(error ? { error } : {}),
      };
      state.summary = calcSummary(state.progress, state.summary);
    },
    setAbortStream: (state, action) => {
      state.abortStream = action.payload;
    },
    setCountChunk: (state, action) => {
      state.countChunk = action.payload;
    },
    setSortedList: (state, action) => {
      state.sortedList = action.payload;
    },
    setChunkIndex: (state, action) => {
      state.chunkIndex = action.payload;
    },
    setPrepareFile: (state, action) => {
      state.prepareFile = action.payload;
    },
  },
});

export default transferSlice.reducer;

export const {
  setUplinking,
  setDownlinking,
  setUploading,
  setProgress,
  setAbortStream,
  setCountChunk,
  setSortedList,
  setChunkIndex,
  setPrepareFile,
} = transferSlice.actions;

export const loadFFmpeg = async () => {
  const ffmpeg = new FFmpeg();
  const baseURL = "https://unpkg.com/@ffmpeg/core@0.12.6/dist/umd";

  try {
    await ffmpeg.load({
      coreURL: await toBlobURL(`${baseURL}/ffmpeg-core.js`, "text/javascript"),
      wasmURL: await toBlobURL(
        `${baseURL}/ffmpeg-core.wasm`,
        "application/wasm"
      ),
    });
  } catch (error) {
    console.error("Failed to load FFmpeg", error);
  }

  return ffmpeg;
};

export const isVideoFragmented = async (file) => {
  if (!file) {
    console.error("No file selected");
    return;
  }

  let isFragmented = false;
  let isH264 = false;

  const mp4boxfile = MP4Box.createFile();

  mp4boxfile.onMoovStart = () => {
    console.log("Starting to parse movie information");
  };

  mp4boxfile.onReady = (info) => {
    if (info.isFragmented) {
      isFragmented = true;
    } else {
      console.log("File is not fragmented");
    }

    const videoTrack = info.tracks.find((track) => track.type === "video");

    if (videoTrack) {
      const codec = videoTrack.codec;
      if (codec.includes("avc1")) {
        isH264 = true;
      } else if (codec.includes("hvc1")) {
        isH264 = false;
      }
    }
  };

  mp4boxfile.onError = (error) => {
    console.error("Error occurred while parsing", error);
  };

  try {
    const arrayBuffer = await file.arrayBuffer();
    arrayBuffer.fileStart = 0;
    const uint8Array = new Uint8Array(arrayBuffer);

    mp4boxfile.appendBuffer(uint8Array.buffer);

    mp4boxfile.flush();

    return { fragmented: isFragmented, h264: isH264 };
  } catch (error) {
    console.error("Could not check if file is fragmented", error);
  }
};

export const fragmentVideoFile = async (file, ffmpeg) => {
  if (!file) {
    console.error("No file selected");
    return;
  }

  try {
    const inputFile = file.name;
    const tempOutputFile = `${file.name
      .split(".")
      .slice(0, -1)
      .join(".")}_temp.mp4`;

    await ffmpeg.writeFile(inputFile, await fetchFile(file));
    try {
      await ffmpeg.exec([
        "-i",
        inputFile,
        "-c",
        "copy",
        "-movflags",
        "+frag_keyframe+empty_moov+default_base_moof",
        tempOutputFile,
      ]);
    } catch (error) {
      console.log("Error executing FFmpeg command:", error);
      return;
    }

    const data = await ffmpeg.readFile(tempOutputFile);
    const outputFileName = `${file.name.split(".").slice(0, -1).join(".")}.mp4`;
    const fragmentedFile = new File([data.buffer], outputFileName, {
      type: "video/mp4",
    });

    return fragmentedFile;
  } catch (error) {
    console.error("Error fragmenting video file:", error);
    return;
  }
};

export const isVideoFile = (file) => {
  let isVideo = false;
  const videoMimeTypes = ["video/mp4", "video/mov"];
  const videoExtensions = [".mp4", ".mov"];

  if (file.type && videoMimeTypes.includes(file.type)) {
    isVideo = true;
  } else {
    const fileExtension = file.name
      .slice(file.name.lastIndexOf("."))
      .toLowerCase();
    if (videoExtensions.includes(fileExtension)) {
      isVideo = true;
    }
  }
  return isVideo;
};

export const containsVideoFile = async (fileList) => {
  for (let i = 0; i < fileList.length; i++) {
    const item = fileList[i];
    let file;
    if (item instanceof DataTransferItem && item.getAsFile) {
      file = item.getAsFile();
    } else if (item instanceof File) {
      file = item;
    }

    if (file && isVideoFile(file)) {
      const { fragmented, h264 } = await isVideoFragmented(file);
      if (fragmented) {
        return { video: true, fragmented: true, h264: h264 };
      } else {
        return { video: true, fragmented: false, h264: h264 };
      }
    }
  }

  return { video: false, fragmented: false };
};

export const prepareVideo = async (file) => {
  const ffmpeg = await loadFFmpeg();
  const fragmentedFile = await fragmentVideoFile(file, ffmpeg);
  return fragmentedFile;
};

export const prepareVideoDialog =
  (maxFileSize) => async (dispatch, getState) => {
    dispatch(
      setDialog({
        dialog: VIDEO,
        maxFileSize,
      })
    );

    //this should resolve
    const waitForPrepareFileChange = () => {
      return new Promise((resolve) => {
        const checkStateChange = () => {
          const currentPrepareFile = selectPrepareFile(getState());
          if (currentPrepareFile !== PrepareFile.NOT_STARTED) {
            resolve(currentPrepareFile);
          } else {
            setTimeout(checkStateChange, 100);
          }
        };
        checkStateChange();
      });
    };

    const prepareFileResult = await waitForPrepareFileChange();
    return prepareFileResult;
  };

function streamToBlob(stream, mimeType) {
  if (mimeType != null && typeof mimeType !== "string") {
    throw new Error("Invalid mimetype, expected string.");
  }
  return new Promise((resolve, reject) => {
    const chunks = [];
    stream
      .on("data", (chunk) => chunks.push(chunk))
      .once("end", () => {
        const blob =
          mimeType != null
            ? new Blob(chunks, { type: mimeType })
            : new Blob(chunks);
        resolve(blob);
      })
      .once("error", reject);
  });
}

function concat(...arrays) {
  const total = arrays.reduce((total, { length }) => total + length, 0);
  const result = new Uint8Array(total);
  let length = 0;
  for (let array of arrays) {
    result.set(array, length);
    length += array.length;
  }
  return result;
}

export const saveBlobToDisk = (blob, name) => {
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = name;
  link.click();
};

export const startDownload =
  (
    file,
    intent = FILE_DOWNLOAD,
    transform = null,
    folderStructure = undefined,
    showPaneTransfer = true
  ) =>
  async (dispatch, getState) => {
    try {
      await dispatch(setUploading(true));
      if (showPaneTransfer) {
        await dispatch(showPane(PANE_TRANSFER));
      }
      const isFolder = !("storageId" in file);
      if (isFolder) {
        // TODO make use of transform
        await dispatch(
          downloadFolder(file, intent, transform, folderStructure)
        );
      } else {
        await dispatch(downloadFile(file, intent, transform));
      }
      await dispatch(setUploading(false));
    } catch (e) {
      Sentry.captureException(e);
      await dispatchToast(dispatch, {
        message: "Error when downloading file(s): " + e,
        icon: "document",
        intent: Intent.DANGER,
        timeout: 5000,
      });
    }
  };

export const downloadFile =
  (file, intent, transform) => async (dispatch, getState) => {
    const onProgress = (progress) =>
      dispatch(
        setProgress({
          file: { ...file, size: file.meta.size },
          progress,
        })
      );

    const isChrome = /Chrome/.test(navigator.userAgent);
    const isLargeFile = file.meta.size > 700 * 1024 * 1024;
    const shouldDownloadStreaming = isChrome && isLargeFile;

    if (shouldDownloadStreaming) {
      console.log("using streaming download method");
      //const onDownloadComplete = () => console.log("Done!");
      //downloadInServiceWorker(file, intent, onProgress, onDownloadComplete);
      await dispatch(startDownloadStreamBackground(file, intent, onProgress));
    } else {
      const onDownloadComplete = async (blob) => {
        if (transform) {
          blob = await transform(file, blob);
        }
        saveBlobToDisk(blob, file.name);
      };
      await dispatch(
        startDownloadBlobBackground(
          file,
          intent,
          onProgress,
          onDownloadComplete
        )
      );
    }
  };

export const downloadFolder =
  (node, intent, transform, folder = null) =>
  async (dispatch, getState) => {
    if (!folder) {
      const api = createApiClient(API_URL_PREFIX);
      const token = await dispatch(getToken());
      folder = await api
        .token(token)
        .files(node.id)
        .get({
          fields: {
            id: true,
            meta: true,
            name: true,
            index: true,
            group: true,
            storageId: true,
            version: true,
            timestamp: true,
            parent: true,
            files: {
              "*": true,
            },
          },
        });
    }
    if ("storageId" in folder) {
      throw new Error("File is not a folder.");
    }

    const getSize = (file) =>
      "storageId" in file
        ? file.meta.size
        : file.files.map(getSize).reduce((i, c) => c + i, 0);
    const totalSize = getSize(folder);

    const zipStream = new ZipStream();
    const zipWriter = zipStream.writable.getWriter();

    let totalWritten = 0;

    const downloadRecursive = async (file, path = "") => {
      if (!("storageId" in file)) {
        for (const child of file.files) {
          await downloadRecursive(
            child,
            path ? `${path}/${file.name}` : file.name
          );
        }
        return;
      }

      // Pipe read stream to write stream
      let controller;
      let semaphore;
      const reader = new ReadableStream({
        start: (readerController) =>
          new Promise((resolve, reject) => {
            controller = readerController;
            semaphore = { resolve, reject };
          }),
      });
      const writer = {
        write: (data) => {
          controller.enqueue(data);
          totalWritten += data.byteLength;
          if (semaphore) {
            semaphore.resolve();
            semaphore = null;
          }
        },
        abort: () => {
          controller.error();
          if (semaphore) {
            semaphore.reject();
            semaphore = null;
          }
        },
        close: controller.close.bind(controller),
      };

      const onProgress = (progress) =>
        dispatch(
          setProgress({
            file: { ...folder, name: folder.name + ".zip", size: totalSize },
            progress: totalWritten / totalSize,
          })
        );

      await dispatch(startDownloadBackground(file, writer, intent, onProgress));

      zipWriter.write({
        name: path ? `${path}/${file.name}` : file.name,
        timestamp: new Date(file.timestamp),
        stream: () => reader,
      });
    };

    let result = new Uint8Array();
    const zipReader = zipStream.readable.getReader();
    zipReader.read().then(function processText({ done, value }) {
      if (done) {
        dispatch(
          setProgress({
            file: { ...folder, name: folder.name + ".zip", size: totalSize },
            progress: 1,
          })
        );
        saveBlobToDisk(
          new Blob([result], { type: "application/octet-stream" }),
          `${folder.name}.zip`
        );
      } else {
        result = concat(result, value);
        return zipReader.read().then(processText);
      }
    });

    for (const child of folder.files) {
      await downloadRecursive(child);
    }

    await zipWriter.close();
  };

export const startDownloadStreamBackground =
  (file, intent = FILE_DOWNLOAD, onProgress = null) =>
  async (dispatch, getState) => {
    const handle = await window.showSaveFilePicker({
      suggestedName: file.name,
    });
    const writable = await handle.createWritable();
    dispatch(startDownloadBackground(file, writable, intent, onProgress));
  };

export const startDownloadBlobBackground =
  (
    file,
    intent = FILE_DOWNLOAD,
    onProgress = null,
    onDownloadComplete = null,
    setVideoChunk,
    setfileLoading
  ) =>
  async (dispatch, getState) => {
    const stream = new TransformStream0();
    const { readable, writable } = stream;
    const reader = readable.getReader();
    let blob;
    let resolve,
      promise = new Promise((r) => (resolve = r));

    let result = new Uint8Array();
    reader.read().then(function processText({ done, value }) {
      if (done) {
        blob = new Blob([result], { type: "application/octet-stream" });
        if (onDownloadComplete) {
          const maybePromise = onDownloadComplete(blob);
          if (typeof maybePromise?.then === "function") {
            maybePromise.then(() => {
              resolve();
            });
          } else {
            resolve();
          }
        } else {
          resolve();
        }
      } else {
        result = concat(result, value);
        return reader.read().then(processText);
      }
    });

    const writer = writable.getWriter();
    await writer.ready;

    await dispatch(
      startDownloadBackground(
        file,
        writer,
        intent,
        onProgress,
        setVideoChunk,
        setfileLoading
      )
    );

    await promise;

    return blob;
  };

export const startDownloadBackground =
  (file, writer, intent, onProgress, setVideoChunk, setFileLoading) =>
  async (dispatch, getState) => {
    file = { ...file, size: file.meta.size };

    const { cryptoClient, credentials } = await dispatch(getContext());
    const accessToken = await dispatch(getToken());

    const keyId = file.meta.key;

    const symKey = await dispatch(getSymKey(credentials.deviceId, keyId));

    // Fetch the public ECDSA key of the sending party
    const decryptor = async (secData) => {
      const senderDeviceId = cryptoClient.getSenderDeviceId(secData);
      const [, publicECDSAKey] = await dispatch(
        cachePublicKeys(senderDeviceId)
      );
      const { secret: data } = await cryptoClient.decrypt(
        publicECDSAKey,
        keyId,
        symKey,
        secData
      );
      return data;
    };

    if (DRY) {
      await writer.close();
      return;
    }

    await downloadAndDecrypt(
      onProgress,
      accessToken,
      file,
      writer,
      decryptor,
      intent,
      dispatch,
      getState,
      setVideoChunk,
      setFileLoading
    );
  };

const handleFileSystemDirectoryEntries = async (fileEntryItFactory) => {
  const rootNode = {
    name: "",
    kids: [],
    file: { name: undefined },
    id: uuid(),
  };

  for await (const entry of fileEntryItFactory()) {
    let currentNode = rootNode;

    const pathParts = entry.path.split("/");

    for (const part of pathParts) {
      let childNode = currentNode.kids.find((kid) => kid.name === part);

      if (!childNode) {
        childNode = createNewNode(entry, part);
        currentNode.kids.push(childNode);
      }

      currentNode = childNode;
    }

    if (!entry.isFolder) {
      currentNode.file = entry.file;
    }
  }
  return rootNode;
};

const createNewNode = (entry, part) => {
  if (entry.isFolder) {
    return { name: part, kids: [], file: { name: part }, id: uuid() };
  } else {
    return { name: part, file: { name: part }, id: uuid() };
  }
};

export const startUpload =
  (files, toGroup, toParent, toZone) => async (dispatch, getState) => {
    dispatch(setUploading(true));
    dispatch(showPane(PANE_TRANSFER));
    let fakeRoot;
    if (files instanceof DataTransferItemList) {
      fakeRoot = await makeTreeFromBrowserFiles(files);
    } else if (files instanceof FileList) {
      fakeRoot = { kids: [] };
      for (let file of files) {
        if (getState().transfer.prepareFile === PrepareFile.CONFIRMED) {
          file = await prepareVideo(file);
          if (!file) {
            await dispatchToast(dispatch, {
              message:
                "Memory exceeded. Please try a smaller file, use a desktop device if you're on mobile, or try reloading the browser.",
              icon: "video",
              intent: Intent.DANGER,
              timeout: 8000,
            });
          }
          dispatch(setPrepareFile(PrepareFile.NOT_STARTED));
        }
        fakeRoot.kids.push({ file });
      }
    } else if (isArray(files)) {
      fakeRoot = { kids: [] };
      for (const file of files) {
        fakeRoot.kids.push({ file });
      }
    } else if (files instanceof FileSystemDirectoryHandle) {
      const fileEntryItFactory = createFileEntryItFactory(files);
      fakeRoot = await handleFileSystemDirectoryEntries(fileEntryItFactory);
    } else {
      throw new Error(`Can't upload: Unsupported type of file list.`);
    }
    try {
      await dispatch(uploadFileTree(fakeRoot, toGroup, toParent, toZone));
      return { status: "OK" };
    } catch (e) {
      Sentry.captureException(e);
      if (e == "HANDLED") {
        return { error: "HANDLED" };
      } else {
        await dispatchToast(dispatch, {
          message: "Error when uploading file(s): " + e,
          icon: "document",
          intent: Intent.DANGER,
          timeout: 5000,
        });
        return { error: e };
      }
    } finally {
      dispatch(setUploading(false));
    }

    //dispatch(makeUploadFileTree(treeToUpload, toGroup, toParent));
    //dispatch(fileShareShow());
  };

export const startUpdate = (zoneId, node, blob) => async (dispatch) => {
  const { id, parent, group, name } = node;

  //blob.name = node.name;
  blob.storageId = node.storageId;

  node = { id, file: blob, name: name.normalize() };

  return await dispatch(uploadFile(node, parent, group, zoneId));
};

const makeTreeFromBrowserFiles = async (files) => {
  if (files.length === 0) {
    return;
  }

  files = [...files]
    .map((x, i) => {
      if (typeof x.webkitGetAsEntry === "function") {
        return x.webkitGetAsEntry();
      } else if (x instanceof DataTransferItem) {
        return {
          isFile: true,
          file(resolve) {
            resolve(x.getAsFile());
          },
        };
      } else if (x instanceof File) {
        return {
          isFile: true,
          file(resolve) {
            resolve(x);
          },
        };
      }
    })
    .filter((maybeNull) => maybeNull);

  const root = createFakeWebKitEntry(files);
  const treeToUpload = await buildTree(root);

  return treeToUpload;
};

const createFakeWebKitEntry = (files) => {
  let alreadyCalled = false;
  return {
    createReader: () => ({
      readEntries: (callback) => {
        const result = alreadyCalled ? [] : files;
        alreadyCalled = true;
        callback(result);
      },
    }),
  };
};

const buildTree = async (item) => {
  if (item.isFile) {
    return { file: await new Promise((success) => item.file(success)) };
  } else {
    const directoryReader = item.createReader();

    const load = async () => {
      return new Promise((success, fail) => {
        directoryReader.readEntries(async (entries) => {
          const files = [];
          for (let entry of entries) {
            const recAnswer = await buildTree(entry);
            files.push(recAnswer);
          }
          success(files);
        });
      });
    };

    const represent = { kids: [], file: { name: item.name } };

    while (true) {
      const someFiles = await load();
      if (!someFiles.length) return represent;
      else represent.kids.push(...someFiles);
    }
  }
};

const uploadFile =
  (node, parentId, groupId, zoneId) => async (dispatch, getState) => {
    const { cryptoClient, credentials } = await dispatch(getContext());
    const { deviceId, privateECDSAKey } = credentials;
    //const shareResource = (/*deviceId,*/ groupId) => async () => {
    //  return {
    //    keyId: "fake-key-id",
    //    chunkEncryptor: chunk => ({ secData: chunk }),
    //    getEncryptedLength: decryptedLength => decryptedLength
    //  };
    //};

    const { name } = node;

    const reader = {
      read: (startByte, endByte) => {
        const reader = new FileReader();
        return new Promise((succ, fail) => {
          reader.onerror = (e) =>
            e.type == "error" &&
            //fail(`Unable to read from file:${node.file.name}`);
            fail(`Unable to read from file:${name}`);
          reader.onload = async () => {
            if (reader.error) fail("its wrong in the file reading");
            succ(reader.result);
          };

          const fileSlice = node.file.slice(startByte, endByte);
          reader.readAsArrayBuffer(fileSlice);
        });
      },
    };

    //const storageId = uid();
    let update = true;
    let { size, /*name,*/ storageId } = node.file;

    if (!storageId || !isString(storageId) || storageId.length < 10) {
      update = false;
      storageId = uid();
    }

    const type = /*mime.lookup(name) ||*/ "application/octet-stream";

    let uploadCredentials;

    if (update) {
      uploadCredentials = await dispatch(getUpdateCredentials(node.id));
    } else {
      uploadCredentials = await dispatch(getUploadCredentials(storageId));
    }

    const { keyId, symKey } = await cryptoClient.generateKey();

    const file = { id: node.id, size, name, type };

    const progress = (progress) => {
      dispatch(setProgress({ file, progress }));
    };

    const encryptor = async (data) => {
      return await cryptoClient.encrypt(
        deviceId,
        privateECDSAKey,
        keyId,
        symKey,
        data
      );
    };

    const getEncryptedLength = (plaintextSize) => {
      return cryptoClient.getEncryptedLength(deviceId, plaintextSize);
    };

    const version = await encryptAndUpload(
      progress,
      reader,
      size,
      type,
      storageId,
      uploadCredentials,
      encryptor,
      getEncryptedLength
    );

    //const meta = { key: keyId, size };
    let meta = {};
    if (node.file.meta && isObject(node.file.meta)) {
      meta = { ...node.file.meta };
    }

    meta.key = keyId;
    meta.size = size;

    const { reason } = await dispatch(
      uploadNode({
        id: node.id,
        zoneId: zoneId,
        //name: node.file.name,
        name: name,
        groupId: groupId,
        parentId: parentId,
        storageId,
        version,
        meta,
        keyId,
        symKey,
        update,
      })
    );

    if (reason) {
      if (reason === "HANDLED") {
        await dispatch(setProgress({ file, error: "", progress: 0 }));
      } else {
        await dispatch(setProgress({ file, error: reason, progress: 0 }));
      }
      if (reason === "HANDLED") {
        throw "HANDLED";
      }
      throw new Error(reason);
    }
  };

const encryptAndUpload = async (
  progress,
  reader,
  size,
  type,
  storageId,
  credentials,
  encryptor,
  getEncryptedLength
) => {
  const ciphertextChunkSize = getEncryptedLength(PLAINTEXT_CHUNK_SIZE);
  const chunkCount = Math.max(Math.ceil(size / PLAINTEXT_CHUNK_SIZE), 1);

  let {
    region,
    bucket,
    federationToken: {
      Credentials: { AccessKeyId, SecretAccessKey, SessionToken },
    },
  } = credentials;

  const s3 = new /*AWS.*/ S3({
    apiVersion: "2006-03-01",
    signatureVersion: "v4",
    accessKeyId: AccessKeyId,
    secretAccessKey: SecretAccessKey,
    sessionToken: SessionToken,
    region,
  });

  if (size === 0) {
    const buffer = new Data(0);
    const { VersionId: version } = await s3
      .putObject({
        Bucket: bucket,
        Key: storageId,
        Body: buffer.getUint8Array(),
      })
      .promise();
    progress(1);
    return version;
  }

  const { UploadId: uploadId } = await s3
    .createMultipartUpload({
      Bucket: bucket,
      Key: storageId,
      ContentType: type,
      Metadata: {
        decryptedsize: Number(size).toString(10),
        chunkcount: Number(chunkCount).toString(10),
        chunksize: Number(ciphertextChunkSize).toString(10),
      },
    })
    .promise();

  let buffer = new Data(0);

  let PartNumber = 1;
  const multiparts = [];
  for (
    let start = 0;
    start < size;
    start = Math.min(start + PLAINTEXT_CHUNK_SIZE, size)
  ) {
    if (start) {
      progress(start / size);
    }
    const plaintextChunk = await reader.read(
      start,
      Math.min(start + PLAINTEXT_CHUNK_SIZE, size)
    );
    const { secData: ciphertextChunk } = await encryptor(
      new Data(plaintextChunk)
    );

    buffer = Data.join([buffer, ciphertextChunk]);

    if (buffer.length > UPLOAD_CHUNK_SIZE) {
      const { ETag } = await s3
        .uploadPart({
          Bucket: bucket,
          Key: storageId,
          Body: buffer.getUint8Array(),
          PartNumber: PartNumber,
          UploadId: uploadId,
        })
        .promise();
      multiparts.push({ ETag, PartNumber });
      PartNumber++;

      buffer = new Data(0);
    }
  }

  if (buffer.length > 0) {
    const { ETag } = await s3
      .uploadPart({
        Bucket: bucket,
        Key: storageId,
        Body: buffer.getUint8Array(),
        PartNumber: PartNumber,
        UploadId: uploadId,
      })
      .promise();
    multiparts.push({ ETag, PartNumber });
    PartNumber++;
  }

  progress(1);

  const completeArgs = {
    Bucket: bucket,
    Key: storageId,
    UploadId: uploadId,
    MultipartUpload: {
      Parts: multiparts,
    },
  };

  const { VersionId: version } = await s3
    .completeMultipartUpload(completeArgs)
    .promise();

  return version;
};

const uploadFileTree =
  (fakeRoot, toGroup, toParent, toZone) => async (dispatch) => {
    if (!toParent) {
      throw new Error("NoParentError");
    }

    const createIdentities = (node) => {
      node.id = node.file?.id || uid();
      node.kids && node.kids.map(createIdentities);
      node.name = node.file?.name?.normalize() || "";
    };

    const uploadTree = async (node, parentId) => {
      if (node.kids) {
        const action = createNode({
          id: node.id,
          zoneId: toZone,
          //name: node.file.name,
          name: node.name,
          groupId: toGroup,
          parentId: parentId,
        });
        const { reason } = await dispatch(action);
        // TODO better error handling
        Sentry.captureException(new Error(reason));
        ////await dispatch(createFile({ name, groupId, parentId: folderId }));
        //await dispatch(
        //  //remoteSync(
        //    createNode({
        //      id: node.id,
        //      name: node.file.name,
        //      groupId: toGroup,
        //      parentId: parentId
        //      //meta: {},
        //    })
        //  //)
        //);
        for (const kid of node.kids) {
          await uploadTree(kid, node.id);
        }
      } else {
        await dispatch(uploadFile(node, parentId, toGroup, toZone));
      }
    };

    createIdentities(fakeRoot);
    startFileProgress(dispatch, fakeRoot);
    for (const kid of fakeRoot.kids) {
      await uploadTree(kid, toParent);
    }
  };

const startFileProgress = (dispatch, root) => {
  for (const kid of root.kids) {
    if (!("kids" in kid)) {
      const file = {};
      file.id = kid.id;
      file.size = kid.file.size;
      //file.name = kid.file.name;
      file.name = kid.name;

      dispatch(setProgress({ file, progress: 0 }));
    } else {
      startFileProgress(dispatch, kid);
    }
  }
};

export const downloadAndDecrypt = async (
  onProgress,
  accessToken,
  restFile,
  writer,
  decryptor,
  intent = FILE_DOWNLOAD,
  dispatch,
  getState,
  setVideoChunk,
  setFileLoading
) => {
  if (!writer) {
    throw new Error("NoFileWriterSuppliedError");
  }

  let {
    region,
    bucket,
    federationToken: {
      Credentials: { AccessKeyId, SecretAccessKey, SessionToken },
    },
  } = await requestDownloadCredentials(accessToken, restFile.id, intent);

  if (!AccessKeyId) {
    return;
  }

  const s3 = new /*AWS.*/ S3({
    apiVersion: "2006-03-01",
    signatureVersion: "v4",
    accessKeyId: AccessKeyId,
    secretAccessKey: SecretAccessKey,
    sessionToken: SessionToken,
    region,
  });

  const signedUrl = s3.getSignedUrl("headObject", {
    Bucket: bucket,
    Key: restFile.storageId,
    VersionId: restFile.version,
  });

  let response;

  response = await fetch(signedUrl, {
    mode: "cors",
    method: "HEAD",
  });

  const file = {};

  file.type = response.headers.get("content-type");
  file.group = restFile.group;
  file.name = restFile.name;
  file.storageId = restFile.storageId;
  file.version = restFile.version;

  if (response.headers.get("content-length") === "0") {
    // Empty file
    onProgress?.(1);
    await writer.close();
    return;
  }

  const fileInfo = {};
  fileInfo.chunkSize = response.headers.get("x-amz-meta-chunksize");
  fileInfo.chunkCount = response.headers.get("x-amz-meta-chunkcount");
  fileInfo.decryptedSize = response.headers.get("x-amz-meta-decryptedsize");
  fileInfo.ContentLength = fileInfo.chunkSize * fileInfo.chunkCount; // response.headers.get("content-length");
  fileInfo.ContentType = response.headers.get("content-type");

  file.chunkSize = parseInt(fileInfo.chunkSize, 10);
  file.numberOfChunks = parseInt(fileInfo.chunkCount, 10);
  file.decryptedSize = parseInt(fileInfo.decryptedSize, 10);
  file.encryptedSize = parseInt(fileInfo.ContentLength, 10);

  if (!fileInfo.ContentLength) {
    throw new UnableToGetContentLengthError(bucket);
  }

  //const keyId = restFile.meta.key;

  //const decrypt = part =>
  //  this.trustManager.revealResource(restFile.meta.key, part);

  const jobs = Array(file.numberOfChunks)
    .fill()
    .map((_, i) => ({
      index: i,
      data: {
        startByte: i * file.chunkSize,
        endByte: Math.min(file.encryptedSize, (i + 1) * file.chunkSize),
      },
    }));

  let i = 0;

  function delay(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  try {
    for (const job of jobs) {
      if (getState().transfer.abortStream) {
        break;
      }

      if (i) {
        onProgress?.(job.data.startByte / file.encryptedSize);
      }

      const encryptedData = await partDownload(job, s3, file, bucket);
      const wrappedData = new Data(encryptedData);
      const decryptedData = await decryptor(wrappedData);
      await writer.write(decryptedData.getUint8Array());

      if (setVideoChunk) {
        setVideoChunk(Array.from(decryptedData.uint8Array));
        await delay(500);
      }

      dispatch(setCountChunk({ count: i + 1, total: file.numberOfChunks }));

      i++;
    }

    if (!writer.locked && !getState().transfer.abortStream) {
      await writer.close();
    }

    onProgress?.(1);
  } catch (e) {
    Sentry.captureException(e);
    if (writer.readyState === "writable") {
      writer.abort();
    }
  } finally {
    try {
      if (writer.readyState === "writable") {
        await writer.close();
      }
    } catch (error) {
      console.error("Failed to close writer:", error);
    }
    if (setVideoChunk) {
      setVideoChunk(undefined);
    }
    if (setFileLoading) {
      setFileLoading(false);
    }
    dispatch(setAbortStream(false));
  }
};

const partDownload = async (job, s3, file, bucket) => {
  const args = {
    Bucket: bucket,
    Key: file.storageId,
    VersionId: file.version,
    Range: `bytes=${job.data.startByte}-${job.data.endByte - 1}`,
  };

  const signedUrl = s3.getSignedUrl("getObject", args);

  return await fetch(signedUrl, {
    headers: {
      Range: args.Range,
    },
  }).then((res) => {
    if (res.status > 199 && res.status < 300) {
      return res.arrayBuffer();
    } else {
      return Promise.reject(res.text());
    }
  });
};

export const selectUploading = (state) => state.transfer.uploading;

export const selectSummary = (state) => state.transfer.summary;

export const selectProgress = (state) => state.transfer.progress;

export const selectAbort = (state) => state.transfer.abort;

export const selectAbortStream = (state) => state.transfer.abortStream;

export const selectCountChunk = (state) => state.transfer.countChunk;

export const selectSortedList = (state) => state.transfer.sortedList;

export const selectChunkIndex = (state) => state.transfer.chunkIndex;

export const selectPrepareFile = (state) => state.transfer.prepareFile;

/////

export const startUplink = () => async (dispatch, getState) => {
  const context = await dispatch(getContext());
  const {
    cryptoClient,
    credentials: { deviceId, privateECDHKey },
  } = context;
  const ws = new WebSocket("wss://konfident.lo/beacon");
  let resolve,
    promise = new Promise((f) => (resolve = f));
  ws.onopen = () => {
    resolve();
  };
  await promise;
  dispatch(setUplinking(true));
  while (getState().transfer.uplinking) {
    ws.send("hello".repeat(10 * 1000));
    let t0 = new Date();
    const arrays = [...Array(1000).keys()].map(() => {
      const array = new Uint8Array(10000);
      crypto.getRandomValues(array);
      return array;
    });
    const chunk = Data.join(arrays);
    let keyObj = JSON.parse(localStorage._keyObj || "null");
    if (!keyObj) {
      keyObj = await cryptoClient.generateKey();
      localStorage._keyObj = JSON.stringify(keyObj);
    }
    const { keyId, symKey } = keyObj;
    const { secData } = await cryptoClient.encrypt(
      deviceId,
      privateECDHKey,
      keyId,
      symKey,
      chunk
    );
    const body = secData.getUint8Array();
    //const blob = new Blob([body], { type: "application/octet-stream" });
    //const link = document.createElement('a')
    //link.href = URL.createObjectURL(blob)
    //link.download = 'filename.enc'
    //link.click()
    //return;
    //let body = "";
    //for (let j = 0; j < arrays.length; j++) {
    //  for (let i = 0; i < arrays[j].length; i++) {
    //    body += String.fromCharCode(parseInt(arrays[j][i]));
    //  }
    //}
    //body = body.substr(0, body.length * 0.67);
    console.log(new Date() - t0);
    await fetch("https://konfident.lo/dev/null", {
      body,
      method: "POST",
      headers: {
        "content-type": "application/octet-stream",
      },
    });
    console.log(new Date() - t0);
    console.log("");
  }
};
